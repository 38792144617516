import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faRunning, faBasketballBall, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import khelologo from '../../assets/img/logo/NationalGamesTransparent.png';
import khelologoWhiteOutline from '../../assets/img/logo/NATIONAL GAMES LOGO_01.png';
// import poweredBy from '../../assets/img/logo/powered-by-sfa.png';
import { Link } from 'react-router-dom';
// import { footerVenueLinks } from 'micro-site/Landing/Venues/data';
// import { useShowKhelo } from 'utils/HooksUtils';
// import { isKhelo } from '../../utils/kheloConditions';

const KheloFooter = () => {
  // const match = useRouteMatch();
  // const routes = {
  //   Home: 'overview',
  //   Sports: 'sports',
  //   'Draws and Schedules': 'draws-schedules',
  //   Attractions: 'attractions',
  //   Gallery: 'gallery',
  //   Results: 'results',
  //   'FAQs & Support': 'faq',
  //   Feedback: 'feedback',
  //   '"How To" Guide': 'gallery/%22How%20To%22%20Guide',
  // };
  const tournamentId = 221; // Hard coding for now not possible in current flow to get dynamic tournament id every where for a generic microsite
  // const isIos = getMobileOperatingSystem() === 'iOS';
  return (
    <div className="md:pt-0 pt-4 bg-blue-navBg">
      <div className="w-full h-full flex flex-col py-3 px-3 min-h-60 text-sm md:text-base max-w-screen-xl text-white items-center gap-4 md:gap-0 mx-auto">
        <div className="flex mb-2 pt-6">
          <Link
            className="text-white ignore-style flex flex-col gap-2"
            to={`/micro-site/${tournamentId}/landing/overview`}
          >
            <img src={khelologoWhiteOutline} className="h-14 md:h-20 mr-2" alt={''} />
          </Link>
          {/* <Link
            className="text-white ignore-style flex flex-col gap-2 border-r-2"
            to={`/micro-site/${tournamentId}/landing/overview`}
          >
            <img src={khelologo} className="h-14 md:h-20 mr-2" alt={''} />
          </Link>
          <Link
            className="text-white ignore-style flex flex-col gap-2"
            to={`/micro-site/${tournamentId}/landing/overview`}
          >
            <img src={poweredBy} className="h-14 md:h-20 ml-2" alt={''} />
          </Link> */}
        </div>
        <div className="flex justify-center items-center gap-4 flex-col w-full h-full md:mt-2">
          <div className={`flex gap-8 md:mt-4 md:pt-0.5 justify-center`}>
            <a
              href="https://instagram.com/NationalGamesGujarat"
              target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-4 md:h-6" />
            </a>
            <a
              href="https://facebook.com/NationalGamesGujarat/"
              target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              {' '}
              <FontAwesomeIcon icon={faFacebookF} className="h-4 md:h-6" />
            </a>
            <a
              href="https://youtube.com/channel/UCiYauvTKlOfDxxLloAYM4yA"
              target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faYoutube} className="h-4 md:h-6" />
            </a>
            <a
              href="https://twitter.com/Nat_Games_Guj"
              target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faTwitter} className="h-4 md:h-6" />
            </a>
          </div>

          <div className="w-fit flex gap-3 text-xxs md:text-sm text-center md:text-left">
            <a
              className="text-white underline font-bold ignore-style"
              href="/static/(SAG) Privacy Policy Draft for NG.pdf"
              target="_blank"
              rel="noreferrer"
            >
              PRIVACY POLICY
            </a>
          </div>

          <div className="text-xxs md:text-sm text-center">
            <p>
              <span className="font-bold">All rights reserved by Sports Authority of Gujarat</span>
            </p>
            <p>
              Powered by{' '}
              <a href="https://sfaplay.com" target="_blank" rel="noreferrer">
                <span className="text-white underline">SFAPLAY.COM</span>
              </a>{' '}
              (NG GUJ_sfaplay)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KheloFooter;
