import React from 'react';

export default function Loader({
  width = 16,
  height = 16,
  color = 'pink-navTxt',
  text,
  className,
}) {
  return (
    <div
      className={
        `w-full h-full flex justify-center items-center ${text ? 'flex-col' : ''} ` + className
      }
    >
      <div
        className={`animate-spin rounded-full h-${height} w-${width} border-b-2 border-${color}`}
      ></div>
      {text ? <div className="mt-2 font-bold text-center">{text}</div> : null}
    </div>
  );
}
