import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import upTriangleP from '../../../assets/img/solidUpTrianglePink.svg';
import downTriangle from '../../../assets/img/solidDownTriangle.svg';

import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className={`menu-items text-left ${depthLevel > !0 && 'hover:bg-white'} cursor-pointer h-12`}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
            className={`flex items-center h-full border-1 w-full border-white hover:text-white ${
              depthLevel > 0 ? 'justify-between w-full border-1' : 'hover:bg-white'
            }`}
          >
            <span
              className={`flex items-center ${
                depthLevel > 0
                  ? 'text-sm font-semibold hover:text-white'
                  : dropdown
                  ? 'text-base font-bold text-pink-navTxt ml-6'
                  : 'text-base font-bold ml-6'
              } text-blue-navBg h-full w-full font-montserrat pr-2 ml-4`}
            >
              {items.title}
            </span>
            {depthLevel > 0 ? (
              <div
                className={`${
                  dropdown && 'bg-blue-navBg2'
                } flex items-center justify-center w-10 h-full`}
              >
                <FontAwesomeIcon icon={faAngleRight} className="text-blue-ngBlue" />
              </div>
            ) : (
              <img src={dropdown ? upTriangleP : downTriangle} className={`ml-1 w-2 h-2`} />
            )}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
        </>
      ) : (
        <Link
          to={items.route}
          className={`h-full w-full ${
            depthLevel > 0 ? 'border-1 border-white' : 'hover:bg-white'
          } hover:text-white`}
        >
          <span
            className={`${
              depthLevel > 0 ? 'hover:text-white' : 'text-base font-bold ml-6'
            } text-sm font-semibold font-montserrat text-blue-navBg flex items-center h-full w-full ml-4`}
          >
            {items.title}
          </span>
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
