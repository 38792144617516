import React from 'react';
import { useBuilderIoPage } from '../../queries/hooks';
import Loader from '../../../components/Loader';

const Index = () => {
  const builderIoPage = useBuilderIoPage({
    tournament: location.pathname.split('/')[2],
    page_path: 'reconciliation',
  });

  return builderIoPage.isLoading ? (
    <Loader />
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html: builderIoPage.data.data.html,
      }}
    />
  );
};

export default Index;
