import { throttle } from 'lodash';
import { useState, useEffect, useMemo, useRef } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
const defaultConfig = resolveConfig();

/**
 * Hook to perform pagination of an array
 * @param {Array} data - An array
 * @param {number} pageSize - no. of elements on each page
 * @returns {Object}  data, setPage , currentPage, maxPage
 */
export const usePagination = (data, pageSize = 10) => {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    const temp = [];
    for (let i = 0; i < data?.length; i += pageSize) {
      temp.push(data.slice(i, i + pageSize));
    }
    setPagination(temp);
    setPage(1);
  }, [data, pageSize]);
  useEffect(() => {
    setCurrentData(pagination?.slice(0, page).reduce((prev, current) => [...prev, ...current], []));
  }, [page, pagination]);
  return {
    data: currentData,
    setPage,
    currentPage: page,
    maxPage: useMemo(() => data?.length / pageSize, [pageSize, data]),
  };
};

/**
* calls a callback whenever it is clicked outside the element
 * @param {React.Ref} ref - ref of the container HTML element
 * @param {function} callback - function which is called when click outside
 * 

 */

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback && callback();
      }
    }
    window.addEventListener('mousedown', handleOutside);
    return () => window.removeEventListener('mousedown', handleOutside);
  }, [ref]);
}

/**
 * Hook to return a state which updates after a specific delay, update timer resets if state or delay changes before the update is done
 * @param {*} state
 * @param {number} delay time between state updates
 */

export function useDebounce(state, delay) {
  const [term, setTerm] = useState(state);
  useEffect(() => {
    const timer = setTimeout(() => setTerm(state), delay);
    return () => clearTimeout(timer);
  }, [state, delay]);
  return term;
}
/**
 *
 * @returns {Object} returns all the env variables set in environment
 */
export function useEnv() {
  const ref = useRef(process.env);
  return ref.current;
}

/**
 *
 * @returns {string} value of  REACT_APP_SHOW_KHELO in environment
 */
export function useShowKhelo() {
  const { REACT_APP_SHOW_KHELO = undefined } = useEnv();
  return REACT_APP_SHOW_KHELO;
}

/**
 *
 * @param {React.ref} ref
 * @returns {{top:Number,left:Number}}
 * A hook to add a scroll listener to an element, and get current top and left position of it
 *
 */

export function useScroll(ref) {
  const [scrollPos, setScrollPos] = useState({ top: 0, left: 0 });
  const debouncedState = useDebounce(scrollPos, 100);
  const scroller = () => {
    if (!ref.current) return;
    if (scrollPos.top !== ref.current.scrollTop || scrollPos.left !== ref.current.scrollLeft) {
      setScrollPos({ top: ref.current.scrollTop, left: ref.current.scrollLeft });
    }
  };
  useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener('scroll', scroller);
    return () => ref.current && ref.current.removeEventListener('scroll', scroller);
  }, [ref, scroller]);
  return debouncedState;
}
/**
 *
 * @param {React.ref} ref react ref to apply the listener to
 * @param {Function} callback function to execute when scroll reaches end
 * @param {boolean} isFetching react query fetching state infinite won't fetch if this comes as true
 * @param {boolean} enabled optional to disable/enable
 *
 */

export function useInfiniteScroll(ref, callback, isFetching, enabled = true) {
  // const scrollState = useScroll(ref);
  useEffect(() => {
    let localIsFetching = false;
    async function temp() {
      if (ref.current)
        if (
          !isFetching &&
          !localIsFetching &&
          ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + 100
        ) {
          localIsFetching = true;
          await (enabled && callback && callback());
          localIsFetching = false;
        }
    }
    const scroller = throttle(temp, 150);
    ref.current && ref.current.addEventListener('scroll', scroller);
    return () => {
      return ref.current && ref.current.removeEventListener('scroll', scroller);
    };
  }, [ref.current, callback, enabled, isFetching]);
}

/**
 *
 * @param {string} breakpoint a tailwind default breakpoint or a css min width in string doesn't support anything user defined in tailwind config for now
 * @returns {boolean} a state denoting if the current breakpoint is active or not
 * checks against min width
 */
export function useBreakPoint(breakpoint) {
  const [isMatched, setMatched] = useState(false);
  const currentBreakPoint = matchMedia(
    `(min-width:${defaultConfig?.theme?.screens?.[breakpoint] || breakpoint})`
  );
  useEffect(() => {
    if (currentBreakPoint.matches) setMatched(true);
    const resizeFunction = () => {
      if (currentBreakPoint.matches) {
        setMatched(true);
      } else setMatched(false);
    };
    window.addEventListener('resize', resizeFunction);
    return () => removeEventListener('resize', resizeFunction);
  }, [breakpoint]);
  return isMatched;
}
