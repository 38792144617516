import { faArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import VenueCard from '../components/VenueCard';
import VenueMobileCard from '../components/VenueMobileCard';
import { landingAllData, city, filterRev } from '../builderData';

export default function All({ tournamentId, setCurCity, isMobile }) {
  const clickHandler = (city) => {
    setCurCity(filterRev[city]);
  };
  return (
    <>
      {!isMobile
        ? landingAllData.map((data, i) => {
            if (i !== 0) {
              return (
                <div className="w-full px-auto mx-10 my-5" key={i}>
                  <p className="text-l font-bold mb-5 text-blue-kheloBlue">{`${city[i]} Venues`}</p>
                  <div className="flex justify-between">
                    {data.map((el, i) => (
                      <VenueCard
                        key={i}
                        {...el}
                        image={el.img}
                        redirectTo={`/micro-site/${tournamentId}/landing/embed-page/${el?.builderId}`}
                      />
                    ))}
                  </div>
                  <div
                    className="text-purple-venueTxt w-31 float-right my-5 bg-yellow-viewVenue rounded-lg font-bold font-roboto p-2 cursor-pointer"
                    onClick={() => clickHandler(city[i])}
                  >
                    View more
                    <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                  </div>
                </div>
              );
            }
            return null;
          })
        : landingAllData.map((data, i) => {
            if (i !== 0) {
              return (
                <div className="mb-5" key={i}>
                  <p className="flex justify-between font-bold mb-5 text-blue-kheloBlue">
                    <span className="text-l">{`${city[i]} Venues`}</span>
                    <div
                      className="text-purple-venueTxt text-sm m-auto mx-0 w-31 underline"
                      onClick={() => clickHandler(city[i])}
                    >
                      View more
                      <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    </div>
                  </p>
                  <div className="grid grid-cols-2 gap-2 md:hidden">
                    {data.map((el, i) => (
                      <VenueMobileCard
                        key={i}
                        image={el.img}
                        name={el.name}
                        redirectTo={`/micro-site/${tournamentId}/landing/embed-page/${el?.builderId}`}
                      />
                    ))}
                  </div>
                </div>
              );
            }
            return null;
          })}
    </>
  );
}
