import BannerImageDesktop from '../../../../assets/img/Venues/NG22/VenueWebBanner.png';
import BannerImageMobile from '../../../../assets/img/Venues/NG22/VenueMobBanner.png';
import Banner from '../components/Banner';
import VenueCard from '../components/VenueCard';
import { useEffect, useState } from 'react';
import { individualData, filter } from '../builderData';
import All from './All';
import VenueMobileCard from '../components/VenueMobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from 'hooks/useWindowDimensions';

const Venues = () => {
  const tournamentId = location.pathname.split('/')[2];
  const windowSize = useWindowDimensions();
  const isMobile = windowSize.width < 768;
  const [venueData, setVenueData] = useState([]);
  const cityCode = [...Object.keys(filter)];
  const [curCity, setCurCity] = useState('All');
  useEffect(() => {
    setVenueData(individualData.AMD);
  }, []);

  useEffect(() => {
    if (curCity !== 'All') setVenueData(individualData[curCity]);
  }, [curCity]);
  const clickHandler = (city) => {
    setCurCity(city);
  };

  return (
    <>
      <Banner img={isMobile ? BannerImageMobile : BannerImageDesktop} />
      {isMobile && curCity !== 'All' ? null : (
        <div className="px-6 mt-11 md:mt-24 pt-1 text-center mb-8">
          <h1 className="font-extrabold text-blue-kheloBlue font-montserrat md:text-4xl mb-2 text-lg">
            Venues
          </h1>
        </div>
      )}
      <div className="p-14 md:flex bg-blue-venueBg flex-wrap gap-2 justify-center hidden">
        {!isMobile && (
          <div className="w-full text-center mb-10">
            {cityCode.map((city) => (
              <span
                className={`mx-3 font-extrabold cursor-pointer text-purple-${
                  city === curCity ? 'venuefilterActive' : 'venuefilter'
                } text-${city === curCity ? '2xl' : '3xs'}`}
                key={city}
                onClick={() => clickHandler(city)}
              >
                {filter[city]}
              </span>
            ))}
          </div>
        )}
        {curCity === 'All' ? (
          <All cityCode={cityCode} tournamentId={tournamentId} setCurCity={setCurCity} />
        ) : (
          venueData.map((el, i) => (
            <VenueCard
              key={i}
              {...el}
              image={el.img}
              redirectTo={`/micro-site/${tournamentId}/landing/embed-page/${el?.builderId}`}
            />
          ))
        )}
      </div>
      <div className="bg-blue-venueBg pt-2">
        {curCity !== 'All' && isMobile && (
          <div className="flex w-full text-center">
            <FontAwesomeIcon
              className="absolute m-1 mx-6 px-3 py-1 border-1 rounded-sm  text-blue-kheloBlue"
              icon={faAngleLeft}
              onClick={() => setCurCity('All')}
            />
            <p className="w-full text-blue-kheloBlue text-lg m-0 font-extrabold">
              {`${filter[curCity]} Venues`}
            </p>
          </div>
        )}
        <div
          className={`grid grid-cols-${curCity === 'All' ? '1' : '2'} gap-2 md:hidden px-6 py-8`}
        >
          {curCity === 'All' ? (
            <All cityCode={cityCode} tournamentId={tournamentId} setCurCity={setCurCity} isMobile />
          ) : (
            venueData.map((el, i) => (
              <VenueMobileCard
                key={i}
                {...el}
                image={el.img}
                redirectTo={`/micro-site/${tournamentId}/landing/embed-page/${el?.builderId}`}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Venues;
