export const menuItems = [
  {
    title: 'About National Games',
    submenu: [
      {
        title: 'History',
        route: '/micro-site/221/landing/embed-page/7e00994ac96e4b5e825e3907c38024d3',
      },
      {
        title: 'IOA',
        route: '/micro-site/221/landing/embed-page/3a2c27434f7d41e7807cf23d23c56567',
      },
      {
        title: 'Kerala 2015',
        route: '/micro-site/221/landing/embed-page/b398607752454f04a6bef57f72d996f8',
      },
    ],
  },
  {
    title: 'NG Gujarat 2022',
    submenu: [
      {
        title: 'Schedule',
        route: '/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf',
      },
      {
        title: 'Sports',
        route: '/micro-site/221/landing/sports',
      },
      {
        title: 'Venues',
        route: '/micro-site/221/landing/venues',
      },
      {
        title: 'Mascot',
        route: '/micro-site/221/landing/embed-page/224ea5c94a96489e8bb6addff9686768',
      },
      {
        title: 'Anthem',
        route: '/micro-site/221/landing/embed-page/bc52068468184edaac4a65c81f3c66a5',
      },
      {
        title: 'Participating States / UT / Unit',
        route: '/micro-site/221/landing/embed-page/58f14d8a9dea47d38d72876446e9bbcd',
      },
      {
        title: 'Sporting Heroes',
        route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
      },
      // {
      //   title: 'Records',
      //   route: '/micro-site/221/landing/embed-page/3f92d9393fe646beb201e176ed54dae0',
      // },
      {
        title: 'Games Secretariat',
        route: '/micro-site/221/landing/embed-page/6a321dd0d58f48388fa02acd2d04d41c',
      },
      {
        title: 'NGG 2022 App',
        route: '/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298',
      },
      {
        title: 'The Organisation',
        route: '/micro-site/221/landing/embed-page/a14ee8f4cbf4472593f8e1a7e9b82d51',
      },
    ],
  },
  {
    title: 'Media & Gallery',
    submenu: [
      {
        title: 'Photos',
        route: '/micro-site/221/landing/gallery',
      },
      {
        title: 'Videos',
        route: '/micro-site/221/landing/gallery',
      },
      // {
      //   title: 'Gujrat Sporting Heroes',
      //   route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
      // },
      // {
      //   title: 'Media Collaterals',
      //   route: '/micro-site/221/landing/embed-page/95ee0e68adec47e8a3bb81809e165112',
      // },
    ],
  },
  // {
  //   title: 'General Info',
  //   submenu: [
  //     {
  //       title: 'Accreditation',
  //       route: '/micro-site/221/landing/embed-page/02ca35b6e9794d6796f051fb601e97b7',
  //     },
  //     {
  //       title: 'Accommodation',
  //       route: '/micro-site/221/landing/embed-page/a03ba2a8d4044ee3860f07d34018b6f3',
  //     },
  //     {
  //       title: 'Catering',
  //       route: '/micro-site/221/landing/embed-page/0c4cd2a4dc844eaf963bfedb569f220b',
  //     },
  //     {
  //       title: 'Transportation',
  //       route: '/micro-site/221/landing/embed-page/8dbfdf4d40d74c5296f84ba51c6d5dc2',
  //     },
  //     {
  //       title: 'Medical Services',
  //       route: '/micro-site/221/landing/embed-page/251aff4a21104abdaaed51d7fcf4aa6e',
  //     },
  //     {
  //       title: 'Emergency Number',
  //       route: '/micro-site/221/landing/embed-page/62317d6fc42f44d18015f94c277e1af3',
  //     },
  //   ],
  // },
];
