import CrowneShootingAcademy from './../../../assets/img/Venues/NG22/Crowne Shooting Academy.jpg';
import DumasBeach from './../../../assets/img/Venues/NG22/Dumas Beach.jpg';
import IITGandhinagar from './../../../assets/img/Venues/NG22/IIT Gandhinagar.jpeg';
import KensvilleGolfAndCountryClub from './../../../assets/img/Venues/NG22/Kensville Golf and Country Club.jpg';
import MahatmaMandir from './../../../assets/img/Venues/NG22/Mahatma Mandir.jpg';
import MajorDhyanchandHockeyGround from './../../../assets/img/Venues/NG22/Major Dhyanchand Hockey Ground.jpg';
import MilitaryRifleClub from './../../../assets/img/Venues/NG22/Military Rifle Club.jpg';
import PDDuINdoorStadium from './../../../assets/img/Venues/NG22/PDDu INdoor Stadium.jpg';
import SabarmatiRIverfront2 from './../../../assets/img/Venues/NG22/Sabarmati RIverfront 2.jpg';
// import SabarmatiRiverfront3 from './../../../assets/img/Venues/NG22/Sabarmati Riverfront 3.jpg-org.jpg';
// import SabarmatiRiverfront from './../../../assets/img/Venues/NG22/Sabarmati Riverfront.jpg';
import SardarVallabhbhaiPatelSwimmingPool from './../../../assets/img/Venues/NG22/Sardar Vallabhbhai Patel Swimming Pool.jpg';
import Sanskardham from './../../../assets/img/Venues/NG22/SANSKARDHAM.jpg';
import Transstadia from './../../../assets/img/Venues/NG22/Transstadia.jpg';
import VelodromeDelhi from './../../../assets/img/Venues/NG22/Velodrome Delhi.jpg';
import logo from './../../../assets/img/logo/favicon.png';

export const individualData = {
  AMD: [
    {
      name: 'The Ahmedabad Military and Rifle Training Association',
      img: MilitaryRifleClub,
      width: '38%',
      builderId: '4404006825a54d6193d89651aa19fa41',
    },
    {
      name: 'Sabarmati Riverfront',
      img: SabarmatiRIverfront2,
      width: '58%',
      builderId: '1775ca35b6bf48fdaa2636df05109e9d',
    },
    {
      name: 'TransStadia',
      img: Transstadia,
      width: '58%',
      builderId: '44d7374d06b04b12a43b9f520f6022de',
    },
    {
      name: 'Crowne Shooting Academy',
      img: CrowneShootingAcademy,
      width: '38%',

      builderId: '76befaeab9114cacb0e8ed34cf4f9d87',
    },
    {
      name: 'Kensville Golf and Country Club',
      img: KensvilleGolfAndCountryClub,
      width: '38%',

      builderId: '3e076747efa245bdac70a62ff6cfb5a3',
    },
    {
      name: 'Sanskardham Ground',
      img: Sanskardham,
      width: '58%',

      builderId: '9155bf53e9f542638df24ec5990ac5af',
    },
    {
      name: 'Police Stadium, Shahibagh',
      img: logo,
      isLogo: true,
      width: '58%',
      builderId: '2a68c745abd94befaffbeeb05a537980',
    },
  ],
  GND: [
    {
      name: 'Mahatma Mandir',
      img: MahatmaMandir,
      width: '38%',
      builderId: '9acf50e5f83445019d6f04a481e4ac33',
    },
    {
      name: 'IIT Gandhinagar',
      img: IITGandhinagar,
      width: '58%',
      builderId: '7567cab08e4a471ca668bba341bb58a2',
    },
    {
      name: 'CHH Road Gandhinagar',
      img: logo,
      isLogo: true,
      width: '58%',
      builderId: 'cb6362d1665c46f3b319510f6266be56',
    },
  ],
  SRT: [
    {
      name: 'Dumas Beach',
      img: DumasBeach,
      width: '38%',
      builderId: '0659e3fb53b742ce8cc188a6ecd50a60',
    },
    {
      name: 'Pandit Deendayal Upadhyay Indoor Stadium',
      img: PDDuINdoorStadium,
      width: '58%',

      builderId: '1f970dfadac84073b874c384a0a8d0b2',
    },
  ],
  VDR: [
    {
      name: 'Multi-Purpose Hall, Sama Sports Complex',
      img: logo,
      isLogo: true,
      width: '38%',
      builderId: 'c15b8389fdc64811b1d604e5373a779f',
    },
  ],
  RKT: [
    {
      name: 'Sardar Patel Swimming Complex',
      img: SardarVallabhbhaiPatelSwimmingPool,
      width: '38%',

      builderId: '36dd1c564a7b401eafbee3c7dab88674',
    },
    {
      name: 'Major Dhyanchand Hockey Ground',
      img: MajorDhyanchandHockeyGround,
      width: '58%',

      builderId: 'c1947b2d0a27425096bd8eaf336c99ea',
    },
  ],
  BNR: [
    {
      name: 'Multi-Purpose Hall, SCB',
      img: logo,
      isLogo: true,
      width: '38%',
      builderId: 'c9a2f0aeed914293ac02c76434aa23cd',
    },
    {
      name: 'Outdoor Courts, SCB',
      img: logo,
      isLogo: true,
      width: '58%',
      builderId: '046d9fe756d941e4a2966fb39affa8cd',
    },
  ],
  DLH: [
    {
      name: 'Cycling Velodrome',
      img: VelodromeDelhi,
      width: '38%',
      builderId: 'c98891f3f2f540719bab2fc4569d3d8f',
    },
  ],
};

export const landingAllData = [
  [],
  [
    // 'Ahmedabad',
    {
      name: 'The Ahmedabad Military and Rifle Training Association',
      img: MilitaryRifleClub,
      width: '49%',
      builderId: '4404006825a54d6193d89651aa19fa41',
    },
    {
      name: 'Sabarmati Riverfront',
      img: SabarmatiRIverfront2,
      width: '49%',
      builderId: '1775ca35b6bf48fdaa2636df05109e9d',
    },
  ],
  [
    // 'Gandhinagar',
    {
      name: 'Mahatma Mandir',
      img: MahatmaMandir,
      width: '49%',

      builderId: '9acf50e5f83445019d6f04a481e4ac33',
    },
    {
      name: 'IIT Gandhinagar',
      img: IITGandhinagar,
      width: '49%',

      builderId: '7567cab08e4a471ca668bba341bb58a2',
    },
  ],
  [
    // 'Surat',
    {
      name: 'Dumas Beach',
      img: DumasBeach,
      width: '49%',
      builderId: '0659e3fb53b742ce8cc188a6ecd50a60',
    },
    {
      name: 'Pandit Deendayal Upadhyay Indoor Stadium',
      img: PDDuINdoorStadium,
      width: '49%',
      builderId: '1f970dfadac84073b874c384a0a8d0b2',
    },
  ],
  [
    // 'Vadodara',
    {
      name: 'Multi-Purpose Hall, Sama Sports Complex',
      img: logo,
      isLogo: true,
      width: '49%',
      builderId: 'c15b8389fdc64811b1d604e5373a779f',
    },
  ],
  [
    // 'Rajkot',
    {
      name: 'Sardar Patel Swimming Complex',
      img: SardarVallabhbhaiPatelSwimmingPool,
      width: '49%',

      builderId: '36dd1c564a7b401eafbee3c7dab88674',
    },
    {
      name: 'Major Dhyanchand Hockey Ground',
      img: MajorDhyanchandHockeyGround,
      width: '49%',

      builderId: 'c1947b2d0a27425096bd8eaf336c99ea',
    },
  ],
  [
    // 'Bhavnagar',
    {
      name: 'Multi-Purpose Hall, SCB',
      img: logo,
      isLogo: true,
      width: '49%',
      builderId: 'c9a2f0aeed914293ac02c76434aa23cd',
    },
    {
      name: 'Outdoor Courts, SCB',
      img: logo,
      isLogo: true,
      width: '49%',
      builderId: '046d9fe756d941e4a2966fb39affa8cd',
    },
  ],
  [
    // 'Delhi',
    {
      name: 'Cycling Velodrome',
      img: VelodromeDelhi,
      width: '49%',
      builderId: 'c98891f3f2f540719bab2fc4569d3d8f',
    },
  ],
];

export const city = [
  'All',
  'Ahmedabad',
  'Gandhinagar',
  'Surat',
  'Vadodara',
  'Rajkot',
  'Bhavnagar',
  'Delhi',
];

export const filter = {
  All: 'All',
  AMD: 'Ahmedabad',
  GND: 'Gandhinagar',
  SRT: 'Surat',
  VDR: 'Vadodara',
  RKT: 'Rajkot',
  BNR: 'Bhavnagar',
  DLH: 'Delhi',
};

export const filterRev = {
  All: 'All',
  Ahmedabad: 'AMD',
  Gandhinagar: 'GND',
  Surat: 'SRT',
  Vadodara: 'VDR',
  Rajkot: 'RKT',
  Bhavnagar: 'BNR',
  Delhi: 'DLH',
};
