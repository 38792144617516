import React from 'react';
import { QueryClientProvider } from 'react-query';
import Routes from './components/routes';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'utils/FormioOverrides';
import { Provider } from 'react-redux';
import Store from './store';
import queryClient from 'utils/queryClient';
import { BrowserRouter as Router } from 'react-router-dom';
// import Reader from 'components/QRcode/Reader';
// Base App component
export default function App() {
  let consoleHolder = console;

  // runs after whole page is rendered recursively
  function debug(bool) {
    if (!bool) {
      consoleHolder = console;
      // eslint-disable-next-line no-global-assign
      console = {};
      Object.keys(consoleHolder).forEach(function (key) {
        console[key] = function () {};
      });
    } else {
      // eslint-disable-next-line no-global-assign
      console = consoleHolder;
    }
  }

  debug(true);

  return (
    <Router>
      <Provider store={Store}>
        <QueryClientProvider client={queryClient}>
          <Routes />
          {/* <Reader content={'HELLO WORLD'} width="256" height="256" /> */}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </Router>
  );
}
