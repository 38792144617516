import Http from 'utils/Http';

const baseUrl = '/api/method';

const apiEndPoints = {
  getTournamentDetails: 'sfa_online.v1.profile.get_tournament_details',
  // getTournamentBasicInfo: '',
  // getTournamentContactDetails: '',
  // getTournamentPriceDetails: '',
  getTournamentSportList: '/sfa_online.v1.microsite.get_sports',
  getSportEventBasicInfo: '/sfa_online.v1.microsite.get_sport_details',
  getSportEventList: '/sfa_online.v1.microsite.get_sport_events',
  getSportEventListDirect: 'sfa_online.v1.microsite.get_sport_events',
  // getSportEventPriceDetails: '',
  // getDrawFormat: 'sfa_online.v1.digital_scoring.get_sport_event',
  getPointsMedals: 'sfa_online.v1.microsite.get_medal_points',
  getRankingTableList: 'sfa_online.v1.microsite.get_rankings',
  getWinnerTable: 'sfa_online.v1.microsite.get_winners',
  getParticipantList: 'sfa_online.v1.microsite.get_draws_and_schedules',
  getSportEvent: 'sfa_online.v1.digital_scoring.get_sport_event',
  getDrawDateList: 'sfa_online.v1.microsite.get_draw_dates',
  getSportList: `sfa_online.v1.digital_scoring.get_sports`,
  getGender: 'sfa_online.v1.digital_scoring.get_gender',
  getAgeGroup: 'sfa_online.v1.digital_scoring.get_age_group',
  getSportCategory: 'sfa_online.v1.digital_scoring.get_sport_category',
  getSubCategory: 'sfa_online.v1.digital_scoring.get_sub_category',
  getBuilderIoPage: 'sfa_online.v1.microsite.get_page',
  getShowReconciliationPage: 'sfa_online.v1.microsite.is_reconciliation_shown',
  getRulesAndPrice: 'sfa_online.v1.microsite.get_rules_and_price',
  getEventAttractions: 'sfa_online.v1.event_attractions.get_event_attractions',
  getSections: 'Gallery Section',
  getFolders: 'Gallery Folder',
  getMedia: 'Gallery Media Item',
  getSportSchedules: 'sfa_online.v1.registrations.schedule_api.get_sport_pdf',
  getDrawsFilter: 'sfa_online.v1.microsite.get_participant_filters',
  getRankingFilters: 'sfa_online.v1.microsite.get_ranking_table_filters',
  getSubCategoryFilters: 'sfa_online.v1.microsite.get_subcategory',
  getGallerySections: 'sfa_online.v1.microsite.get_gallery_sections',
  getGalleryFolders: 'sfa_online.v1.microsite.get_section_folders',
  getGalleryMedia: 'sfa_online.v1.microsite.get_folder_media',
  getNews: 'sfa_online.v1.news.get_news',
  getParticularNews: 'sfa_online.v1.news.get_news_details',
  getAdvanceLeagueScoring:
    'sfa_online.v1.advance_ds.league_knockout_ds.get_league_knockout_ds_record',
  getAdvancedScoring: 'sfa_online.v1.microsite.get_advanced_scoring',
  getEventTypes: 'sfa_online.v1.event_attractions.get_all_types',
  getEvents: 'sfa_online.v1.event_attractions.get_attractions_by_type',
  getGalleryBySports: 'sfa_online.v1.microsite.get_sport_folders',
  getSportsName: 'sfa_online.v1.microsite.get_sports_name',
  getStatesName: 'sfa_online.v1.registrations.signup.get_states',
  getParticipantsName: 'sfa_online.v1.reconciliation.get_ng_records', // This is to be came from backend
};

export async function getTournamentDetails(params) {
  //   try {
  //     // const res = await fetch(baseUrl + apiEndPoints.getTournamentDetails).then((resp) =>
  //     //   resp.json()
  //     // );
  //     // return res.message.details;
  //     const res = {
  //       eventName: 'Khelo India Youth Games 2021-22, Haryana',
  //       city: 'Chandigarh',
  //       eventImage: '',
  //       banner: '',
  //       price: '350',
  //       remarks: 'This is one time tournament fees',
  //       startDate: '2022-02-04T00:00:00+05:30',
  //       endDate: '2022-02-15T00:00:00+05:30',
  //     };
  //     return res;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTournamentDetails}?tournament_id=${params.tournament_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentBasicInfo(_params) {
  try {
    // const res = await fetch(
    //   baseUrl + apiEndPoints.getTournamentBasicInfo + `?tournament_id=${params.tournamentId}`
    // ).then((resp) => resp.json());
    // return res.message.details;
    return [
      {
        title: 'Sport Details',

        content: [
          {
            header: 'Description',

            value:
              'Khelo India Youth Games is a part of the revamped national programme for development of sports, Khelo India. After the resounding success of the Khelo India School Games in 2018, it is back in 2019 in a much bigger and better version as the Youth Games. For the first time students from colleges and universities are participating, and will have a total of 9000 participants. Now, these events will be held in Haryana.',
          },
          // {
          // header: 'Registration Dates',

          //   value: '20/07/2021 - 4/08/2021',
          // },
          {
            header: 'Tournament Venue',

            value: 'Khel Bhawan, Tau Devi Lal Sports Complex, Sector 3, Panchkula-134109',
          },
        ],
      },
    ];
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentContactDetails(_params) {
  try {
    // const res = await fetch(
    //   baseUrl + apiEndPoints.getTournamentContactDetails + `?tournament_id=${params.tournamentId}`
    // ).then((resp) => resp.json());
    // return res.message.details;
    return [
      {
        title: 'Contact Information',

        content: [
          {
            header: 'Contact Person',

            value: 'Darlene Robertson',
          },
          {
            header: 'Contact Number',

            value: '(209) 555-0104',
          },
          {
            header: 'Address ',

            value: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
          },
          {
            header: 'Email ID',

            value: 'georgia.young@example.com',
          },
        ],
      },
    ];
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentPriceDetails(_params) {
  try {
    // const res = await fetch(
    //   baseUrl + apiEndPoints.getTournamentPriceDetails + `?tournament_id=${params.tournamentId}`
    // ).then((resp) => resp.json());
    // return res.message.details;
    return [
      {
        title: 'Prizes, Rules & Regulations Information',
        content: [
          {
            header: 'Rules and Regulations (General)',

            value:
              'Participate in the U/18 SFAPLAY.COM SUPER 30 Tennis Tournament 2021 CHALLENGE  - an international online chess tournament, open for ages 18 & below.',

            pdf_url: 'http://localhost:3000',
          },

          {
            header: 'Prize Information',

            value:
              'Participate in the U/18 SFAPLAY.COM SUPER 30 Tennis Tournament 2021 CHALLENGE  - an international online chess tournament, open for ages 18 & below.',

            pdf_url: 'http://localhost:3000',
          },
        ],
      },
    ];
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentSportList(params) {
  try {
    const res = await fetch(
      baseUrl + apiEndPoints.getTournamentSportList + `?tournament=${params.tournamentId}`
    ).then((resp) => resp.json());
    const arr = res.message;
    const returnData = [];
    arr.map((item) => {
      returnData.push({
        img: item.image,
        title: item.sport,
        tags: [],
        status: null,
        button: null,
        detail_tags: [
          `${item.events} ${item.events === 1 ? 'Event' : 'Events'}`,
          item.city,
          `${item.start_date} - ${item.end_date}`,
        ],
        venue: item.venue,
      });
      return 0;
    });
    return returnData;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventBasicInfo(params) {
  try {
    const res = await fetch(
      baseUrl +
        apiEndPoints.getSportEventBasicInfo +
        `?tournament=${params.tournament}&sport=${params.sport}`
    ).then((resp) => resp.json());
    const newArr = [];
    const [item] = res.message;
    // console.log('item', item);
    newArr.push({
      title: 'Sport Details',
      content: [
        {
          header: 'Sport Name',
          value: item.sport_name,
          image: item.image,
        },
        {
          header: 'Events Dates',
          value: {
            from: item.start_date,
            to: item.end_date,
          },
        },
        {
          header: 'Tournament Venue',
          value: {
            address: item.venue,
          },
        },
      ],
    });
    return newArr;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventList({
  tournament,
  sport = '',
  age_group: ageGroup = '',
  gender = '',
  date = '',
  fees_range: feesRange = '',
}) {
  try {
    const res = await fetch(
      baseUrl +
        apiEndPoints.getSportEventList +
        `?tournament=${tournament}&sport=${sport}&age_group=${ageGroup}&gender=${gender}&date=${date}&fees_range=${feesRange}`
    ).then((resp) => resp.json());
    const newArr = [];
    res.message.forEach((item, index) => {
      // const [SportName, subCategory] = item.sport_event.split('-');
      newArr.push({
        sport_event: item.sport_event,
        sport_image: item.sport_image,
        age_group: item.age_group,
        gender: item.gender,
        sport: item.sport,
        type: item.type,
        sub_category: item.sub_category,
        id: index,
        // title: `${SportName}${subCategory ? '-' + subCategory : ''}`,
        title: `${item.sport}`,
        tags: [
          {
            text: item.gender,
            icon: item.gender,
            icon_color: 'fill-white',
            color: 'text-white',
            bg_color: 'bg-blue-highlight',
          },
          {
            text: item.age_group,
            icon: 'age_group',
            icon_color: 'text-white',
            color: 'text-white',
            bg_color: 'bg-orange-highlight',
          },
        ],
        price: `${item.currency === 'Rupees' ? '₹' : null} ${item.price}`,
        status: {
          text: item.status,
          color: 'text-gray-500',
          bg_color: 'bg-gray-300',
        },
        registration_open: item.registration_open,
        detail_tags: [`${item.start_date} - ${item.end_date}`],
      });
      // return 0;
    });
    return newArr;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventPriceDetails(_params) {
  try {
    // const res = await fetch(
    //   baseUrl +
    //   apiEndPoints.getSportEventPriceDetails +
    //   `?tournament_id=${params.tournamentId}&sport_event_id=${params.sportEventId}`
    // ).then((resp) => resp.json());
    // return res.message.details;
    return [
      {
        title: 'Prizes, Rules & Regulations Information',

        content: [
          {
            header: 'Rules and Regulations (General)',

            value:
              'Participate in the U/18 SFAPLAY.COM SUPER 30 Tennis Tournament 2021 CHALLENGE  - an international online chess tournament, open for ages 18 & below.',

            pdf_url: 'http://localhost:3000',
          },

          {
            header: 'Prize Information',

            value:
              'Participate in the U/18 SFAPLAY.COM SUPER 30 Tennis Tournament 2021 CHALLENGE  - an international online chess tournament, open for ages 18 & below.',

            pdf_url: 'http://localhost:3000',
          },
        ],
      },
    ];
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    console.log(error);
  }
}

export async function getDrawFormat(params) {
  // console.log('params', params);

  const fetchURL =
    baseUrl +
    apiEndPoints.getDrawFormat +
    `?tournament=${params.tournament}&sport=${params.sport}&gender=${params.gender}&age_group=${params.age_group}`;
  if (typeof params.sport_category !== 'object')
    fetchURL.concat(`&sport_category=${params.sport_category}`);
  if (typeof params.sub_category !== 'object')
    fetchURL.concat(`&sub_category=${params.sub_category}`);
  try {
    const res = await fetch(fetchURL).then((resp) => resp.json());
    return res.message[0];
  } catch (e) {
    console.log(e);
  }
}

export async function getSportEventListDirect(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportEventListDirect}?tournament=${params.tournament}&sport=${params.sport}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getPointsMedals(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getPointsMedals}?tournament=${params.tournament}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getRankingTableList(params, offset) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getRankingTableList}?tournament=${params.tournament}&sport=${params?.sport}&event=${params?.event}&medal=${params?.medal}&round=${params?.round}&offset=${offset}&limit=${params.limit}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getWinnerTable(params, offset) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getWinnerTable}?tournament=${params.tournament}&recent=${
        params?.recent
      }&sport=${params?.sport}${offset ? `&offset=${offset}` : ''}${
        params.limit ? `&limit=${params.limit}` : ''
      }${params.subSport ? `&sub_category=${params.subSport}` : ''}`, // Discuss with backend person
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantList(params, offset = 0) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getParticipantList}?tournament=${params.tournament}&date=${params.date}&sport=${params.sport}&round=${params.round}&gender=${params.gender}&age_group=${params.age_group}&offset=${offset}&limit=${params.limit}&search=${params.search}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEvent(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportEvent}?tournament=${params.tournament}&sport=${params.sport}&gender=${params?.gender}&age_group=${params?.age_group}&sport_category=${params.sport_category}&sub_category=${params.sub_category}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getDrawDateList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getDrawDateList}?tournament=${params.tournament}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportList}?tournament=${params.tournament}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getGender(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getGender}?tournament=${params.tournament}&sport=${params.sport}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getAgeGroup(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAgeGroup}?tournament=${params.tournament}&sport=${params.sport}&gender=${params.gender}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportCategory(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportCategory}?tournament=${params.tournament}&sport=${params.sport}&gender=${params.gender}&age_group=${params.age_group}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSubCategory(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSubCategory}?tournament=${params.tournament}&sport=${params.sport}&gender=${params.gender}&age_group=${params.age_group}&sport_category=${params.sport_category}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getRules({ tournamentId, sport }) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getRulesAndPrice}?tournament=${tournamentId}&sport=${sport}`,
    });
    if (!res.message?.error) {
      return [
        {
          title: 'Rules & Regulations Information',
          content: [
            {
              header: 'Rules and Regulations (General)',

              value: res?.message?.rules_and_regulations,

              pdf_url: res?.message?.rules_and_regulation_file,
            },

            {
              header: 'Prize Information',

              value: res?.message?.prize_information,

              pdf_url: res?.message?.prize_information_file,
            },
          ],
        },
      ];
    }
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function getBuilderIoPage(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getBuilderIoPage}?tournament=${params.tournament}&page_path=${params.page_path}`,
    });
    return res.message;
  } catch (error) {
    console.log({ error });
  }
}

export async function getShowReconciliation(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getShowReconciliationPage}?tournament=${params.tournament}`,
    });
    return res.message;
  } catch (error) {
    console.log({ error });
  }
}

export async function getEventAttractions(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getEventAttractions}?tournament_id=${params.tournament_id}`,
    });
    return res.message;
  } catch (error) {
    console.log({ error });
  }
}

export async function getSectionList(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getSections}?filters=[["tournament","=","${params.tournament_id}"]]&order_by=sequence_number&limit_page_length=12`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}

export async function getSectionDetails(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getSections}/${encodeURIComponent(params.section_name)}`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}

export async function getFolderList(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getFolders}?filters=[["type","=","${encodeURIComponent(
        params.section_name
      )}"]]&order_by=sequence_number&limit_page_length=12`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}

export async function getFolderDetails(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getFolders}/${encodeURIComponent(params.folder_name)}`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}
export async function getFolderListBySport(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getFolders}?filters=[["sport","=","${encodeURIComponent(
        params.sport
      )}"]]&order_by=sequence_number&limit_page_length=12`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}
export async function getMediaList(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getMedia}?filters=[["folder","=","${encodeURIComponent(
        params.folder_name
      )}"],["hide","=",0]]&order_by=sequence_number&limit_page_length=12`,
    });
    console.log(res.data); // loop over this data
    return await Promise.all(
      res.data.map((folder) => getMediaDetails({ media_item: folder.name }))
    );
  } catch (error) {
    console.log({ error });
  }
}

export async function getMediaDetails(params) {
  try {
    const res = await Http.fetchDB({
      apiEndpoint: `${apiEndPoints.getMedia}/${encodeURIComponent(params.media_item)}`,
    });
    return res.data;
  } catch (error) {
    console.log({ error });
  }
}

export async function getSportSchedules({ tournamentId }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportSchedules}?tournament_id=${tournamentId}`,
    });
  } catch (error) {
    console.log(error.message);
  }
}

export async function getDrawsFilter(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getDrawsFilter}?tournament=${params.tournamentId}${
        params.date ? `&date=${params.date}` : ''
      }${params.sport ? `&sport=${params.sport}` : ''}`,
    });
  } catch (error) {
    console.error(error.message);
  }
}

export async function getRankingFilters(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getRankingFilters}?tournament=${params.tournamentId}`,
    });
  } catch (e) {
    console.error(e.message);
  }
}
export async function getSubCategoryFilters(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSubCategoryFilters}?tournament=${params.tournamentId}&sport=${params.sport}`,
    });
  } catch (e) {
    console.error(e.message);
  }
}
export const getGallerySections = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getGallerySections}?tournament_id=${params.tournament}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};
export const getGalleryFolders = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getGalleryFolders}?section=${encodeURIComponent(
        params.section
      )}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};
export const getGalleryMedia = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getGalleryMedia}?folder=${encodeURIComponent(params.folder)}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getNews = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getNews}?tournament_id=${params.tournamentId}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getParticularNews = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getParticularNews}?news=${params.newsTitle}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getAdvanceDsLeague = async (_params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAdvanceLeagueScoring}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getAdvanceScoring = async (params) => {
  const gamesBasedSetText = {
    kabaddi: 'Half',
    basketball: 'Quater',
    netball: 'Quater',
    hockey: 'Quater',
    'kho kho': 'Inning',
    softball: 'Inning',
    volleyball: 'Set',
    football: 'Half',
    rugby7s: 'Half',
    'lawn bowl': 'Ends',
    'beach sports': 'Set',
  };
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAdvancedScoring}?match=${params.matchId}`,
    });
    const message = typeof res.message === 'object' ? res.message : JSON.parse(res.message);
    console.log({ message });
    if (message.component === 'Racket') {
      const data = {
        component: message.component,
        ...message,
        Athlete1: {
          ...message.athletes[0],
          wonIn: message.athletes[0].won,
          isWon: message.athletes[0]?.is_won,
          color: '#5d5fef',
          sets: message.sets.map((item, i) => {
            return {
              ...item[0],
              name: `${
                gamesBasedSetText?.[message?.sport?.toLowerCase()]
                  ? gamesBasedSetText?.[message?.sport?.toLowerCase()]
                  : message?.is_team && !message?.is_doubles
                  ? 'Match'
                  : 'set'
              } ${i + 1}`,
            };
          }),
        },
        Athlete2: {
          ...message.athletes[1],
          isWon: message.athletes[1]?.is_won,
          wonIn: message.athletes[1].won,
          color: '#f59e0b',
          sets: message.sets.map((item, i) => {
            return {
              ...item[1],
              name: `${
                gamesBasedSetText?.[message?.sport?.toLowerCase()]
                  ? gamesBasedSetText?.[message?.sport?.toLowerCase()]
                  : message?.is_team && !message?.is_doubles
                  ? 'Match'
                  : 'set'
              } ${i + 1}`,
            };
          }),
        },
      };

      if (message?.sport?.toLowerCase() === 'kho kho') {
        data.sets = data.sets.filter((el, i) => i < 2);
        data.Athlete1.sets = data.Athlete1.sets.filter((el, i) => i < 2);
        data.Athlete2.sets = data.Athlete2.sets.filter((el, i) => i < 2);
        data.available_sets[0] = data.sets.length;
      }

      return data;
    } else {
      return {
        ...message,
        teams: message.teams.map((el, i) => {
          return {
            ...el, // assuming always equal arrays
            substitutes: el?.substituteOut?.map((el2, i) => {
              return {
                ...el2,
                in: el?.substituteIn?.[i]?.athleteIndex,
                out: el2?.athleteIndex,
                athleteIndex: null,
              };
            }),
          };
        }),
      };
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const getEventsType = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getEventTypes}?tournament_id=${params.tournamentId}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getEventsByType = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getEvents}?type=${encodeURIComponent(params.type)}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};
export const getGalleryBySports = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getGalleryBySports}?sport=${params.sport}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};

export const getSportsName = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportsName}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};
export const getStatesName = async (params) => {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getStatesName}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
};
export async function getParticipantsName(params, offset = 0) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getParticipantsName}?tournament=${params.tournament}&sport=${params.sport}&gender=${params?.gender}&state=${params.state}&sub_category=${params.sub_category}&name=${params.name}&offset=${offset}&limit=${params.limit}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}
