import React from "react";
import DetailItemLoader from "../DetailItemLoader";
import MatchCenterMobile from "../../profiles/Tournaments/MatchFixtures/components/MatchCenterMobile";
import { MatchCenterModal } from "../../profiles/Tournaments/MatchFixtures/components/MatchCenterModal";
import MatchCenterPage from "../../profiles/Tournaments/MatchFixtures/components/MatchCenterPage";
import Modal from "react-modal";
import { useDsScoring } from "../../micro-site/queries/hooks";
import Div100vh from "../../components/layouts/Div100Vh";

const AdvancedDsComponent = ({ MatchId, closeModal, state }) => {
  Modal.setAppElement("#root");
  const query = useDsScoring({
    matchId: MatchId,
  });
  return (
    <Modal
      isOpen={state}
      bodyOpenClassName={"overflow-hidden no-scrollbar"}
      onRequestClose={() => {
        if (closeModal) closeModal();
      }}
      className={
        "p-0 w-full md:h-auto fixed md:w-1/2 md:left-1/2 md:top-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 border-none bg-white overflow-y-scroll md:rounded-lg no-scrollbar md:max-h-90-screen h="
      }
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "100",
        },
        content: {
          WebkitOverflowScrolling: "touch",
          outline: "none",
        },
      }}
    >
      <DetailItemLoader queryData={query}>
        {({ data, refetch, isFetching }) => {
          return (
            <Div100vh className="overflow-y-auto">
              {data?.component === "Racket" ? (
                <>
                  <MatchCenterMobile
                    MatchCenterData={data}
                    closeModal={closeModal}
                    refetch={refetch}
                    isFetching={isFetching}
                    matchStatus={data?.match_status?.toLowerCase()}
                  />
                  <MatchCenterModal
                    data={data}
                    closeModal={closeModal}
                    refetch={refetch}
                    isFetching={isFetching}
                    matchStatus={data?.match_status?.toLowerCase()}
                  />
                </>
              ) : data?.component === "Team" ? (
                <MatchCenterPage
                  data={data}
                  closeModal={closeModal}
                  refetch={refetch}
                  isFetching={isFetching}
                  matchStatus={data?.match_status?.toLowerCase()}
                />
              ) : null}
            </Div100vh>
          );
        }}
      </DetailItemLoader>
    </Modal>
  );
};

export default AdvancedDsComponent;
