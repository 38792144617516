import React from 'react';
// import { Helmet } from 'react-helmet';
// import InformationCard from '../../Components/InformationCard';
// import ListLoader from 'components/ListLoader';
// import calendar from '../../../assets/img/micro_calendar.svg';
// import city from '../../../assets/img/city_icon.svg';
// import clipBg from '../../../assets/img/clip-sm-rectangle.png';
// import logo from '../../../assets/img/TournamentLogo.jpg';
// import logo from '../../../assets/img/logo/uttarakhanda.png';
// import DetailItemLoader from 'components/DetailItemLoader';
// import { getDateInMonthDateYear } from 'constants/DateFunctions';

// import {
//   // useTournamentBasicInfo,
//   // useTournamentContactDetails,
//   // useTournamentPriceDetails,
//   useTournamentDetails,
// } from 'micro-site/queries/hooks';
import Loader from 'components/Loader';
import { BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';

export default function Overview({ customPropertyFromDetails }) {
  // const tournamentBasicInfoQuery = useTournamentBasicInfo({
  //   tournament_id: location.pathname.split('/')[2],
  // });
  // const tournamentContactQuery = useTournamentContactDetails({
  //   tournament_id: location.pathname.split('/')[2],
  // });
  // const tournamentPriceQuery = useTournamentPriceDetails({
  //   tournament_id: location.pathname.split('/')[2],
  // });

  // const tournamentDetailsQuery = useTournamentDetails({
  //   tournament_id: location.pathname.split('/')[2],
  // });

  // if (tournamentDetailsQuery.isFetching)
  //   return (
  //     <div className=" h-full w-full">
  //       {' '}
  //       <Loader />
  //     </div>
  //   );
  // console.log(tournamentDetailsQuery);
  return (
    <div className="h-full w-full overflow-hidden">
      {/* <Helmet>
        <title>Home Page | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Home to 8,500 Athletes competing across 25 Sports. Follow for LATEST UPDATES as India's best U/18 athletes battle it out at the KIYG Haryana 2021!"
        />
        <meta property="og:title" content="Home Page | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Home to 8,500 Athletes competing across 25 Sports. Follow for LATEST UPDATES as India's best U/18 athletes battle it out at the KIYG Haryana 2021!"
        />
      </Helmet> */}
      <BuilderComponent
        apiKey={process.env.REACT_APP_BUILDER_IO_API_KEY}
        entry={process.env.REACT_APP_BUILDER_IO_ENTRY_KEY}
        model="page"
      >
        <Loader />
      </BuilderComponent>
    </div>
  );
  // return (
  //   <div className="md:mt-8 ">
  //     <div className="md:mt-10 relative pb-8 lg:pb-60">
  //       <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
  //         {({ data }) => {
  //           return (
  //             <div className="md:w-3/4 lg:w-3/5 px-4 md:px-16 flex flex-col items-start md:items-center  mx-auto max-w-fhd">
  //               <div className="hidden lg:flex border bg-blue-950 border-gray-300 rounded-full h-20 w-20 items-center justify-center">
  //                 <img src={logo} alt="logo" className="h-16" />
  //               </div>
  //               <h1 className="md:text-4xl hidden md:block font-bold text-blue-350 text-center leading-10 mt-6">
  //                 {data?.name}
  //               </h1>
  //               <h3 className="text-lg font-bold mt-2 md:hidden text-blue-350">Details</h3>
  //               <hr className="w-16 mx-0 my-0 md:w-60 h-0.5 md:h-1 mt-1 md:mt-6 bg-blue-350" />
  //               <p className=" mt-3 md:mt-5  md:text-center md:leading-6 text-xs md:text-base text-primary-800">
  //                 For athletes, high altitude produces two contradictory effects on performance. For
  //                 explosive events (sprints up to 400 metres, long jump, triple jump) the reduction
  //                 in atmospheric pressure means there is less resistance from the atmosphere and the
  //                 athlete's performance will generally be better at high altitude.
  //               </p>
  //               <div className="md:mt-12 mt-5 w-full flex flex-col md:flex-row  md:justify-between">
  //                 <div className="flex gap-2.5 md:gap-4 items-center mb-6 md:mb-0">
  //                   <img src={calendar} className="md:w-12 md:h-12 w-9 h-9" alt="calendar" />
  //                   <div>
  //                     <p className="text-xs md:text-sm gray-lighter">Event Dates</p>
  //                     <p className="text-primary-750 text-sm md:text-base mt-1 font-semibold font-roboto">
  //                       {getDateInMonthDateYear(data?.start_date) || 'TBD'} -{' '}
  //                       {getDateInMonthDateYear(data?.end_date) || 'TBD'}
  //                     </p>
  //                   </div>
  //                 </div>
  //                 <div className="flex gap-2.5 md:gap-4 items-center">
  //                   <img src={city} className="md:w-12 md:h-12 w-9 h-9" alt="calendar" />
  //                   <div>
  //                     <p className="text-xs md:text-sm gray-lighter">Tournament Venue</p>
  //                     <p className="text-primary-750 text-sm md:text-base mt-1 font-semibold font-roboto">
  //                       {data?.city}
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           );
  //         }}
  //       </DetailItemLoader>
  //       <div className="absolute hidden -bottom-1 w-full lg:block">
  //         <img src={clipBg} className="w-full" alt="" />
  //       </div>
  //     </div>
  //     <div className="relative ">
  //       <div className="bg-fade-purple pt-4 md:pt-11 pb-8 md:pb-20 px-4 md:px-16 xl:px-32">
  //         <div className="max-w-fhd mx-auto">
  //           <ListLoader queryData={tournamentContactQuery} queryKey="contactInfo">
  //             {({ item, index }) => {
  //               return (
  //                 <InformationCard
  //                   key={index}
  //                   index={index}
  //                   color={'white'}
  //                   title={item?.title}
  //                   content={item?.content}
  //                   shadow={false}
  //                 />
  //               );
  //             }}
  //           </ListLoader>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="px-4 md:px-16 xl:px-32 bg-white max-w-fhd mx-auto">
  //       <ListLoader queryData={tournamentBasicInfoQuery} queryKey="basicInfo">
  //         {({ item, index }) => {
  //           return (
  //             <InformationCard
  //               key={index}
  //               index={index}
  //               color={'blue-350'}
  //               len={tournamentBasicInfoQuery?.data?.length}
  //               title={item?.title}
  //               content={item?.content}
  //               shadow={true}
  //             />
  //           );
  //         }}
  //       </ListLoader>

  //       <ListLoader queryData={tournamentPriceQuery} queryKey="price">
  //         {({ item, index }) => {
  //           return (
  //             <InformationCard
  //               key={index}
  //               index={index}
  //               color={'blue-350'}
  //               len={tournamentPriceQuery?.data?.length}
  //               title={item?.title}
  //               content={item?.content}
  //               shadow={true}
  //             />
  //           );
  //         }}
  //       </ListLoader>
  //     </div>
  //   </div>
  // );
}
