const Banner = ({ title, img, variant }) => {
  return (
    <div className="relative">
      <img src={img} className={`w-full object-cover ${variant !== 'page' ? '' : 'h-40-screen'}`} />
      <h1 className="font-extrabold text-white font-montserrat text-xl md:text-4xl absolute left-14 bottom-8 md:bottom-20 right-14">
        {title}
      </h1>
      <div className="flex">
        <div className="bg-orange-950 h-1.5 md:h-2 w-full"></div>
        <div className="h-1.5 md:h-2 w-full bg-green-500"></div>
        <div className="h-1.5 md:h-2 w-full bg-blue-kheloBlue200"></div>
        <div className="h-1.5 md:h-2 w-full bg-orange-950"></div>
      </div>
    </div>
  );
};

export default Banner;
