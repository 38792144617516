import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Athlete from '../../../assets/img/Athlete.png';
import downTriangle from '../../../assets/img/solidDownTriangle.svg';
import upTriangleP from '../../../assets/img/solidUpTrianglePink.svg';
import upTriangle from '../../../assets/img/solidUpTriangle.svg';
import ProfileIcon from '../../../assets/img/user.svg';
import { logOut } from 'authentication/services/authentication';
import signOut from '../../../assets/img/log-out.svg';
import settingsIcon from '../../../assets/img/settings.svg';

export default function Dropdown({ title, menu }) {
  const [close2, setClose2] = useState();
  const history = useHistory();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`${open ? 'text-opacity-90' : ''} font-bold text-blue-navBg truncate
          text-blue-navTxt text-base ignore-style outline-none`}
          >
            <span
              onClick={
                open
                  ? () => {
                      close2 ? setClose2(false) : setClose2(true);
                    }
                  : () => setClose2(false)
              }
              className={`flex items-center ${open && 'text-pink-navTxt'}`}
            >
              {title}
              <img src={open ? upTriangleP : downTriangle} className={`ml-2 w-2 h-2`} />
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute z-10 w-52 lg:w-60 top-5 pt-4 sm:px-0 lg:max-w-3xl ${
                close2 ? 'hidden' : 'block'
              }`}
            >
              <div className="overflow-hidden shadow-lg">
                <span className="h-1 w-36 rounded-t-sm bg-pink-navTxt flex"></span>
                <div className="relative bg-blue-navBg3" onClick={() => setClose2(true)}>
                  {menu.map((item, idx) => (
                    <div
                      key={idx}
                      onClick={() => history.push(`${item.route}`)}
                      className={`flex text-blue-navBg items-center cursor-pointer transition duration-150 ease-in-out hover:bg-pink-navTxt focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 py-2 border-1 hover:text-white`}
                    >
                      <div className="ml-4">
                        <p className="text-sm mt-1 w-full">{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export const MobDropdown = ({ userProfileQuery, setOpenNavbar, title, menu, isProfile }) => {
  const [profileOpen, setProfileOpen] = useState(false);
  const history = useHistory();
  return (
    <div className="border-1">
      <div
        className={`text-blue-navBg text-sm font-bold font-montserrat h-12 pl-6 flex justify-between`}
        onClick={() =>
          setProfileOpen((prevData) => {
            return !prevData;
          })
        }
      >
        {isProfile ? (
          <div className="flex justify-center items-center">
            <div
              className="h-9 w-9 rounded-full flex-shrink-0 inline-block"
              style={{
                backgroundImage: `url('${userProfileQuery?.data?.message?.image || Athlete}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></div>
            <h1 className="text-sm font-bold text-blue-kheloBlue font-montserrat ml-3">
              {(userProfileQuery?.isSuccess && userProfileQuery?.data?.message?.full_name) ||
                'Guest User'}
            </h1>
          </div>
        ) : (
          <div className="flex justify-center items-center">{title}</div>
        )}
        <div className={`flex justify-center items-center w-14 ${profileOpen && 'bg-blue-navBg2'}`}>
          <img src={profileOpen ? upTriangle : downTriangle} className="w-2" />
        </div>
      </div>
      <div className={`${profileOpen ? 'block' : 'hidden'} bg-blue-navBg2 border-1`}>
        {menu?.map((item, idx) =>
          item.appLink ? (
            <a
              onClick={() => setOpenNavbar(false)}
              key={idx}
              target="_blank"
              rel="noreferrer"
              href={item.route}
              className="border-1 text-xs h-9 flex items-center"
            >
              <span className="text-blue-navBg pl-9 font-semibold font-montserrat">
                {item.name}
              </span>
            </a>
          ) : (
            <div
              key={idx}
              className={`text-blue-navBg text-xs pl-9 h-9 font-semibold font-montserrat flex items-center ${
                idx < menu.length - 1 && 'border-b-1'
              } border-blue-navBg3`}
              onClick={() => {
                setOpenNavbar(false);
                setProfileOpen((prevData) => {
                  return !prevData;
                });
                history.push(`${item.route}`);
              }}
            >
              {item.name}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export const ProfileDropdown = ({ userProfileQuery }) => {
  const [close2, setClose2] = useState();
  const history = useHistory();

  return (
    <Popover className="relative lg:mr-3.5">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
          ${open ? '' : 'text-opacity-90'}
          text-white group bg-orange-700 px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 m-3`}
          >
            <span
              data-cy="user_icon"
              onClick={
                open
                  ? () => {
                      close2 ? setClose2(false) : setClose2(true);
                    }
                  : () => setClose2(false)
              }
            >
              <div
                src={userProfileQuery?.data?.message?.image}
                alt="icon"
                style={{
                  borderRadius: '50%',

                  background: `url('${userProfileQuery?.data?.message?.image || Athlete}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
              />
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                close2 ? 'hidden' : 'block'
              }`}
            >
              <div className="overflow-hidden rounded-lg shadow-lg">
                <div
                  className="relative flex-col gap-8 bg-pink-navTxt p-4"
                  onClick={() => setClose2(true)}
                >
                  <div
                    onClick={() =>
                      history.push(`/${userProfileQuery?.data?.message?.primary_role}/profiles`)
                    }
                    className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                  >
                    <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                      <img
                        src={ProfileIcon}
                        className="mt-1"
                        alt="logout"
                        height="16px"
                        width="16px"
                      />
                    </div>
                    <div className="ml-4">
                      <p data-cy="my_account_icon" className="text-sm mt-1  text-white">
                        My Profile
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      history.push(
                        `/${userProfileQuery?.data?.message?.primary_role}/profiles/account-details`
                      )
                    }
                    className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                  >
                    <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                      <img
                        src={settingsIcon}
                        className="mt-1"
                        alt="logout"
                        height="16px"
                        color="white"
                        width="16px"
                      />
                    </div>
                    <div className="ml-4">
                      <p data-cy="my_account_icon" className="text-sm mt-1  text-white">
                        My Account
                      </p>
                    </div>
                  </div>

                  <div
                    onClick={() => logOut(userProfileQuery?.data)}
                    className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                  >
                    <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                      <img src={signOut} className="mt-1" alt="logout" height="16px" width="16px" />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm mt-1 font-medium text-white">Logout</p>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
