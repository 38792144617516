// import BannerImageDesktop from './../../../assets/img/Venues Banner.jpeg';
// import BannerImageMobile from './../../../assets/img/Venues Banner Mobile.jpeg';
// import a from './../../../assets/img/Venues/Image.png';
// import b from './../../../assets/img/Venues/Image (1).png';
// import c from './../../../assets/img/Venues/Image (2).png';
// import d from './../../../assets/img/Venues/Image (3).png';
// import e from './../../../assets/img/Venues/Image (4).png';
// import f from './../../../assets/img/Venues/Image (5).png';

// Athletics Track, PKL
import AthleticsTrackPKL1 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-1.jpg';
import AthleticsTrackPKL2 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-2.jpg';
import AthleticsTrackPKL3 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-3.jpg';
import AthleticsTrackPKL4 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-4.jpg';
import AthleticsTrackPKL5 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-5.jpg';
import AthleticsTrackPKL6 from './../../../assets/img/Venues/Athletics Track, PKL/Athletics Track, PKL-6.jpg';

// Badminton Hall, PKL1
import BadmintonHallPKL1 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL1.jpg';
import BadmintonHallPKL2 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL2.jpg';
import BadmintonHallPKL3 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL3.jpg';
import BadmintonHallPKL4 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL4.jpg';
import BadmintonHallPKL5 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL5.jpg';
import BadmintonHallPKL6 from './../../../assets/img/Venues/Badminton Hall, PKL1/Badminton Hall, PKL6.jpg';

// Gymnastics Hall, Ambala
import GymnasticsHallAmbala1 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala1.jpg';
import GymnasticsHallAmbala2 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala2.jpg';
import GymnasticsHallAmbala3 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala3.jpg';
import GymnasticsHallAmbala4 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala4.jpg';
import GymnasticsHallAmbala5 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala5.jpg';
import GymnasticsHallAmbala6 from './../../../assets/img/Venues/Gymnastics Hall, Ambala/Gymnastics Hall, Ambala6.jpg';

// Hockey Stadium, PKL
import HockeyStadiumPKL1 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL1.jpg';
import HockeyStadiumPKL2 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL2.jpg';
import HockeyStadiumPKL3 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL3.jpg';
import HockeyStadiumPKL4 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL4.jpg';
import HockeyStadiumPKL5 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL5.jpg';
import HockeyStadiumPKL6 from './../../../assets/img/Venues/Hockey Stadium, PKL/Hockey Stadium, PKL6.jpg';

// Hockey Stadium, Shahabad
import HockeyStadiumShahabad1 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad1.jpg';
import HockeyStadiumShahabad2 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad2.jpg';
import HockeyStadiumShahabad3 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad3.jpg';
import HockeyStadiumShahabad4 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad4.jpg';
import HockeyStadiumShahabad5 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad5.jpg';
import HockeyStadiumShahabad6 from './../../../assets/img/Venues/Hockey Stadium, Shahabad/Hockey Stadium, Shahabad6.jpg';

// MHP - 2, PKL
import MHP2PKL1 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL1.jpg';
import MHP2PKL2 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL2.jpg';
import MHP2PKL3 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL3.jpg';
import MHP2PKL4 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL4.jpg';
import MHP2PKL5 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL5.jpg';
import MHP2PKL6 from './../../../assets/img/Venues/MHP - 2, PKL/MHP - 2, PKL6.jpg';

// MHP - 3, PKL
import MHP3PKL1 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL1.jpg';
import MHP3PKL2 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL2.jpg';
import MHP3PKL3 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL3.jpg';
import MHP3PKL4 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL4.jpg';
import MHP3PKL5 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL5.jpg';
import MHP3PKL6 from './../../../assets/img/Venues/MHP - 3, PKL/MHP - 3, PKL6.jpg';

// Swimming Complex, Ambala
import SwimmingComplexAmbala1 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala1.jpg';
import SwimmingComplexAmbala2 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala2.jpg';
import SwimmingComplexAmbala3 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala3.jpg';
import SwimmingComplexAmbala4 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala4.jpg';
import SwimmingComplexAmbala5 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala5.jpg';
import SwimmingComplexAmbala6 from './../../../assets/img/Venues/Swimming Complex, Ambala/Swimming Complex, Ambala6.jpg';

// Swimming Complex, Ambala
import WarmupTrackAthletics1 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics1.jpg';
import WarmupTrackAthletics2 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics2.jpg';
import WarmupTrackAthletics3 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics3.jpg';
import WarmupTrackAthletics4 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics4.jpg';
import WarmupTrackAthletics5 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics5.jpg';
import WarmupTrackAthletics6 from './../../../assets/img/Venues/Warmup Track Athletics/Warmup Track Athletics6.jpg';

export const footerVenueLinks = [
  {
    name: 'Markandeswar Hockey Stadium',
    route: 'hockey-stadium-tdl',
  },
  {
    name: 'War Heroes Stadium',
    route: 'swimming-complex-ambala',
  },
  {
    name: 'Tau Devi Lal Stadium',
    route: 'tau-devi-lal',
  },
  {
    name: 'View All',
    route: '',
  },
];

export const individualData = {
  'tau-devi-lal': {
    name: 'Tau Devi Lal Stadium',
    description:
      'Tau Devi Lal Stadium is a multi-sport complex which has various facilities. It is hosting the maximum sports for Khelo India Youth Games',
    subtitle: '',
    address: '',
    page_image: HockeyStadiumPKL2,
    card_image: BadmintonHallPKL3,
    gallery_images: [
      { image: AthleticsTrackPKL1, size: 'big' },
      { image: HockeyStadiumPKL2, size: 'medium' },
      { image: BadmintonHallPKL3, size: 'small' },
      { image: MHP2PKL4, size: 'small' },
      { image: MHP3PKL5, size: 'medium' },
      { image: AthleticsTrackPKL6, size: 'big' },
    ],
    location: 'https://www.google.com/maps/place/Tau+Devi+Lal+Stadium',
  },
  'hockey-stadium-tdl': {
    name: 'Markandeswar Hockey Stadium',
    description:
      'This stadium serves as the home ground for the hockey club, Punjab Warriors, of Hockey India League.',
    subtitle: '',
    address: '',
    page_image: HockeyStadiumPKL4,
    card_image: HockeyStadiumPKL5,
    gallery_images: [
      { image: HockeyStadiumPKL1, size: 'big' },
      { image: HockeyStadiumPKL2, size: 'medium' },
      { image: HockeyStadiumPKL3, size: 'small' },
      { image: HockeyStadiumPKL4, size: 'small' },
      { image: HockeyStadiumPKL5, size: 'medium' },
      { image: HockeyStadiumPKL6, size: 'big' },
    ],
    location: 'https://maps.app.goo.gl/Yhv8QX25cRuiAqbm7',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'swimming-complex-ambala': {
    name: 'War Heroes Stadium',
    description: 'War Heroes Memorial Stadium is a multi-purpose stadium',
    subtitle: '',
    address: '',
    page_image: SwimmingComplexAmbala6,
    card_image: SwimmingComplexAmbala6,
    gallery_images: [
      { image: SwimmingComplexAmbala1, size: 'big' },
      { image: SwimmingComplexAmbala2, size: 'medium' },
      { image: SwimmingComplexAmbala3, size: 'small' },
      { image: SwimmingComplexAmbala4, size: 'small' },
      { image: SwimmingComplexAmbala5, size: 'medium' },
      { image: SwimmingComplexAmbala6, size: 'big' },
    ],
    location: 'https://maps.app.goo.gl/ZvnftY5mqywPFnyAA',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'athletics-track-tdl': {
    name: 'Athletics Track Tau Devi Lal',
    description:
      'Tau Devi Lal Stadium is a multi-sport complex which has various facilities. It is hosting the maximum sports for Khelo India Youth Games',
    subtitle: '',
    address: '',
    page_image: AthleticsTrackPKL3,
    card_image: AthleticsTrackPKL6,
    gallery_images: [
      { image: AthleticsTrackPKL1, size: 'big' },
      { image: AthleticsTrackPKL2, size: 'medium' },
      { image: AthleticsTrackPKL3, size: 'small' },
      { image: AthleticsTrackPKL4, size: 'small' },
      { image: AthleticsTrackPKL5, size: 'medium' },
      { image: AthleticsTrackPKL6, size: 'big' },
    ],
    location: 'https://www.google.com/maps/place/Tau+Devi+Lal+Stadium',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'badminton-hall-tdl': {
    name: 'Badminton Hall, Tau Devi Lal',
    description:
      'Tau Devi Lal Stadium is a multi-sport complex which has various facilities. It is hosting the maximum sports for Khelo India Youth Games',
    subtitle: '',
    address: '',
    page_image: BadmintonHallPKL4,
    card_image: BadmintonHallPKL4,
    gallery_images: [
      { image: BadmintonHallPKL1, size: 'big' },
      { image: BadmintonHallPKL2, size: 'medium' },
      { image: BadmintonHallPKL3, size: 'small' },
      { image: BadmintonHallPKL4, size: 'small' },
      { image: BadmintonHallPKL5, size: 'medium' },
      { image: BadmintonHallPKL6, size: 'big' },
    ],
    location: 'https://www.google.com/maps/place/Tau+Devi+Lal+Stadium',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    // ],
  },
  'gymnastics-hall-ambala': {
    name: 'Gymnastics Hall, Ambala',
    description: 'War Heroes Stadium is a multi-purpose stadium.',
    subtitle: '',
    address: '',
    page_image: GymnasticsHallAmbala6,
    card_image: GymnasticsHallAmbala6,
    gallery_images: [
      { image: GymnasticsHallAmbala1, size: 'big' },
      { image: GymnasticsHallAmbala2, size: 'medium' },
      { image: GymnasticsHallAmbala3, size: 'small' },
      { image: GymnasticsHallAmbala4, size: 'small' },
      { image: GymnasticsHallAmbala5, size: 'medium' },
      { image: GymnasticsHallAmbala6, size: 'big' },
    ],
    location: 'https://maps.app.goo.gl/ZvnftY5mqywPFnyAA',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'hockey-stadium-shahabad': {
    name: 'Hockey Stadium, Shahabad',
    description: 'Description Here',
    subtitle: '',
    address: '',
    page_image: HockeyStadiumShahabad5,
    card_image: HockeyStadiumShahabad6,
    gallery_images: [
      { image: HockeyStadiumShahabad1, size: 'big' },
      { image: HockeyStadiumShahabad2, size: 'medium' },
      { image: HockeyStadiumShahabad3, size: 'small' },
      { image: HockeyStadiumShahabad4, size: 'small' },
      { image: HockeyStadiumShahabad5, size: 'medium' },
      { image: HockeyStadiumShahabad6, size: 'big' },
    ],
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'mhp-2-tdl': {
    name: 'MHP - 2, Tau Devi Lal',
    description:
      'Tau Devi Lal Stadium is a multi-sport complex which has various facilities. It is hosting the maximum sports for Khelo India Youth Games',
    subtitle: '',
    address: '',
    page_image: MHP2PKL6,
    card_image: MHP2PKL6,
    gallery_images: [
      { image: MHP2PKL1, size: 'big' },
      { image: MHP2PKL2, size: 'medium' },
      { image: MHP2PKL3, size: 'small' },
      { image: MHP2PKL4, size: 'small' },
      { image: MHP2PKL5, size: 'medium' },
      { image: MHP2PKL6, size: 'big' },
    ],
    location: 'https://www.google.com/maps/place/Tau+Devi+Lal+Stadium',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'mhp-3-tdl': {
    name: 'MHP - 3, Tau Devi Lal',
    description:
      'Tau Devi Lal Stadium is a multi-sport complex which has various facilities. It is hosting the maximum sports for Khelo India Youth Games',
    subtitle: '',
    address: '',
    page_image: MHP3PKL4,
    card_image: MHP3PKL6,
    gallery_images: [
      { image: MHP3PKL1, size: 'big' },
      { image: MHP3PKL2, size: 'medium' },
      { image: MHP3PKL3, size: 'small' },
      { image: MHP3PKL4, size: 'small' },
      { image: MHP3PKL5, size: 'medium' },
      { image: MHP3PKL6, size: 'big' },
    ],
    location: 'https://www.google.com/maps/place/Tau+Devi+Lal+Stadium',
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
  'warmup-track-athletics': {
    name: 'Warmup Track Athletics',
    description: 'Description Here',
    subtitle: '',
    address: '',
    page_image: WarmupTrackAthletics6,
    card_image: WarmupTrackAthletics6,
    gallery_images: [
      { image: WarmupTrackAthletics1, size: 'big' },
      { image: WarmupTrackAthletics2, size: 'medium' },
      { image: WarmupTrackAthletics3, size: 'small' },
      { image: WarmupTrackAthletics4, size: 'small' },
      { image: WarmupTrackAthletics5, size: 'medium' },
      { image: WarmupTrackAthletics6, size: 'big' },
    ],
    // similar_venues: [
    //   {
    //     width: '48%',
    //     slug: 'athletics-track-pkl',
    //   },
    //   {
    //     width: '48%',
    //     slug: 'badminton-hall-tdl',
    //   },
    // ],
  },
};

export const cardData = [
  {
    width: '38%',
    slug: 'swimming-complex-ambala',
  },
  {
    width: '58%',

    slug: 'athletics-track-tdl',
  },
  {
    width: '58%',

    slug: 'badminton-hall-tdl',
  },
  {
    width: '38%',

    slug: 'gymnastics-hall-ambala',
  },
  {
    width: '96.66%',

    slug: 'hockey-stadium-tdl',
  },
  {
    width: '38%',

    slug: 'hockey-stadium-shahabad',
  },
  {
    width: '58%',

    slug: 'mhp-2-tdl',
  },
  {
    width: '58%',

    slug: 'mhp-3-tdl',
  },
  {
    width: '38%',

    slug: 'warmup-track-athletics',
  },
];
