import React from 'react';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import 'constants/DrawEventForm';
import MainPage from './components/MainPage.js';
// import MoreVideos from './components/MoreVideos.js';
// import FoldersInSection from './components/FoldersInSection.js';
// import MorePhotosByCategory from './components/MorePhotosByCategory.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Golf from '../../../assets/img/golf.png';
import Sprint from '../../../assets/img/sprint.png';
// import Relay from '../../../assets/img/relay.png';
import Swimming from '../../../assets/img/swimming.png';
import Tennis from '../../../assets/img/tennis.png';
import Wrestling from '../../../assets/img/wrestling.png';
import cycle from '../../../assets/img/cycle.png';

import swimmingImg1 from '../../../assets/img/img-1.png';
import swimmingImg2 from '../../../assets/img/img-2.png';
import swimmingImg3 from '../../../assets/img/img-3.png';
import swimmingImg4 from '../../../assets/img/img-4.png';
import swimmingImg5 from '../../../assets/img/img-5.png';
import swimmingImg6 from '../../../assets/img/img-6.png';
import { Route, useRouteMatch } from 'react-router-dom';
import CustomSwitch from 'components/routes/CustomSwitch.js';

export const galleryData = [
  {
    sport_name: 'Golf',
    gender: 'Male',
    age_category: 'U-19',
    event: 'Golf',
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: Golf,
      },
    ],
    videos: [],
  },
  {
    sport_name: 'Swimming',
    gender: 'male',
    age_category: 'U-19',
    event: 'Swimming Finals',
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: Swimming,
      },
      {
        id: 0,
        img_url: swimmingImg1,
      },
      {
        id: 0,
        img_url: swimmingImg2,
      },
      {
        id: 0,
        img_url: swimmingImg3,
      },
      {
        id: 0,
        img_url: swimmingImg4,
      },
      {
        id: 0,
        img_url: swimmingImg5,
      },
      {
        id: 0,
        img_url: swimmingImg6,
      },
    ],
    videos: [],
  },
  {
    sport_name: 'Athletics',
    gender: 'male',
    age_category: 'U-19',
    event: 'Athletics',
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: Sprint,
      },
    ],
    videos: [],
  },
  {
    sport_name: 'Cycling',
    gender: 'male',
    age_category: 'U-19',
    event: "16Km Cycling - U'19",
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: cycle,
      },
    ],
    videos: [],
  },
  {
    sport_name: 'Tennis',
    gender: 'male',
    age_category: 'U-19',
    event: 'Tennis Finals - U19',
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: Tennis,
      },
    ],
    videos: [],
  },
  {
    sport_name: 'Wrestling',
    gender: 'male',
    age_category: 'U-19',
    event: 'Wrestling U-21',
    date: 'October 21, 2021',
    images: [
      {
        id: 0,
        img_url: Wrestling,
      },
    ],
    videos: [],
  },
];

export default function index() {
  // const [screen, setScreen] = useState('main_page');

  /*
  const handleSwitch = (screen) => {
    switch (screen) {
      case 'main_page':
        return <MainPage setScreen={setScreen} data={galleryData} />;
      case 'more_videos':
        return <MoreVideos setScreen={setScreen} data={galleryData} />;
      case 'more_photos_by_sport':
        return <FoldersInSection setScreen={setScreen} data={galleryData} />;
      case 'more_photos_by_category':
        return <MorePhotosByCategory setScreen={setScreen} data={galleryData} />;

      default:
        return <MainPage setScreen={setScreen} data={galleryData} />;
    }
  };
*/

  const match = useRouteMatch('/micro-site/:tournament/landing/gallery');
  // Driver Code
  return (
    <>
      <CustomSwitch>
        <Route exact path={`${match.url}/`} component={MainPage} />
      </CustomSwitch>
    </>
  );
}
