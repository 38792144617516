import React, { useState } from 'react';
import { AthleteInformationCard, showFinalHideFinal } from './MatchCenterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
// import Image from '../../../../assets/img/Charts.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from '../../../Components/Modal';
import { getDateAndTime } from 'constants/DateFunctions';
import { realTimeStatus } from 'utils/constants';
import LightGallery from 'lightgallery/react';

const MatchCenterMobile = ({ MatchCenterData, closeModal, isFetching, refetch }) => {
  const videos = [
    {
      media_name: 'Video 1',
      type: 'Video(youtube)',
      video_id: 'pDZdnbI0MAc',
    },
    {
      media_name: 'Video 2',
      type: 'Video(vimeo)',
      video_id: '690508923',
    },
    {
      media_name: 'Video 1',
      type: 'Video(youtube)',
      video_id: 'pDZdnbI0MAc',
    },
    {
      media_name: 'Video 2',
      type: 'Video(vimeo)',
      video_id: '690508923',
    },
    {
      media_name: 'Video 1',
      type: 'Video(youtube)',
      video_id: 'pDZdnbI0MAc',
    },
    {
      media_name: 'Video 2',
      type: 'Video(vimeo)',
      video_id: '690508923',
    },
  ];
  const settings = {
    initialSlide: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const matchStatus = MatchCenterData?.match_status?.toLowerCase();
  const sumOfPoints = (Arr) => {
    let sum = 0;
    Arr.forEach((el) => {
      sum += el.Points;
    });
    return sum;
  };
  console.log('This is it :- ', MatchCenterData);
  const hideFinalFlag = MatchCenterData?.is_team
    ? showFinalHideFinal[MatchCenterData.sport.toLowerCase()]?.sub_category?.[
        MatchCenterData.sub_category
      ] === undefined ||
      showFinalHideFinal[MatchCenterData.sport.toLowerCase()]?.sub_category?.[
        MatchCenterData.sub_category
      ]
      ? showFinalHideFinal[MatchCenterData.sport?.toLowerCase()]?.team
      : false
    : showFinalHideFinal[MatchCenterData?.sport?.toLowerCase()];
  return (
    <>
      {modal ? <Modal setModalClose={setModal} data={modalData} raw /> : null}
      <div className={`md:hidden`} onClick={(e) => e.stopPropagation()}>
        <div className={'flex flex-col flex-grow p-3 gap-3 justify-between md:hidden'}>
          <button
            data-cy="back_btn"
            className="flex items-center cursor-pointer my-2"
            onClick={closeModal}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={`md:fa-xl w-3 md:text-gray-750
           text-gray-750
                  `}
            />
            <p
              data-cy="head"
              className={`md:text-xl text-small md:text-gray-750 
                    text-gray-750
                   md:ml-4 ml-2.5 font-bold`}
            >
              {'Matches and Fixtures'}
            </p>
          </button>

          <div
            className={
              'flex font-roboto text-sm justify-center gap-1 bg-gray-100 rounded-sm bg-gray-125 text-gray-650'
            }
          >
            {/* <img src={Image} alt={''} />
            View Detailed Analytics */}
            {realTimeStatus[matchStatus] ? (
              <>
                Current Score:
                {MatchCenterData.Athlete1.sets.length > 1 ||
                MatchCenterData.Athlete2.sets.length > 1
                  ? MatchCenterData.scoringType === 'PointBased'
                    ? sumOfPoints(MatchCenterData.Athlete1.sets)
                    : MatchCenterData.Athlete1.wonIn
                  : MatchCenterData.Athlete1.sets?.[0]?.Points}
                -
                {MatchCenterData.Athlete1.sets.length > 1 ||
                MatchCenterData.Athlete2.sets.length > 1
                  ? MatchCenterData.scoringType === 'PointBased'
                    ? sumOfPoints(MatchCenterData.Athlete2.sets)
                    : MatchCenterData.Athlete2.wonIn
                  : MatchCenterData.Athlete2.sets?.[0]?.Points}
              </>
            ) : !MatchCenterData.is_draw ? (
              <>
                {MatchCenterData?.Athlete1?.isWon
                  ? MatchCenterData?.Athlete1?.name?.split('(')?.[0]
                  : MatchCenterData?.Athlete2?.name?.split('(')?.[0]}{' '}
                Beat{' '}
                {MatchCenterData?.Athlete2?.isWon
                  ? MatchCenterData?.Athlete1?.name?.split('(')?.[0]
                  : MatchCenterData?.Athlete2?.name?.split('(')?.[0]}{' '}
                by{' '}
                {MatchCenterData.Athlete1.sets.length > 1 ||
                MatchCenterData.Athlete2.sets.length > 1
                  ? MatchCenterData.scoringType === 'PointBased'
                    ? Math.max(
                        sumOfPoints(MatchCenterData.Athlete1.sets),
                        sumOfPoints(MatchCenterData.Athlete2.sets)
                      )
                    : Math.max(MatchCenterData.Athlete1.wonIn, MatchCenterData.Athlete2.wonIn)
                  : Math.max(
                      MatchCenterData.Athlete1.sets?.[0]?.Points,
                      MatchCenterData.Athlete2.sets?.[0]?.Points
                    )}
                -
                {MatchCenterData.Athlete1.sets.length > 1 ||
                MatchCenterData.Athlete2.sets.length > 1
                  ? MatchCenterData.scoringType === 'PointBased'
                    ? Math.min(
                        sumOfPoints(MatchCenterData.Athlete1.sets),
                        sumOfPoints(MatchCenterData.Athlete2.sets)
                      )
                    : Math.min(MatchCenterData.Athlete2.wonIn, MatchCenterData.Athlete1.wonIn)
                  : Math.min(
                      MatchCenterData.Athlete1.sets?.[0]?.Points,
                      MatchCenterData.Athlete2.sets?.[0]?.Points
                    )}
              </>
            ) : (
              <>
                The Match Ended in a Draw with{' '}
                {MatchCenterData?.Athlete1?.sets?.length > 1 ||
                MatchCenterData?.Athlete2?.sets?.length > 1
                  ? MatchCenterData?.scoringType === 'PointBased'
                    ? Math.max(
                        sumOfPoints(MatchCenterData?.Athlete1?.sets),
                        sumOfPoints(MatchCenterData?.Athlete2?.sets)
                      )
                    : Math.max(MatchCenterData?.Athlete1?.wonIn, MatchCenterData?.Athlete2?.wonIn)
                  : Math.max(
                      MatchCenterData?.Athlete1?.sets?.[0]?.Points,
                      MatchCenterData?.Athlete2?.sets?.[0]?.Points
                    )}
                -
                {MatchCenterData.Athlete1.sets.length > 1 ||
                MatchCenterData.Athlete2.sets.length > 1
                  ? MatchCenterData.scoringType === 'PointBased'
                    ? Math.min(
                        sumOfPoints(MatchCenterData.Athlete1.sets),
                        sumOfPoints(MatchCenterData.Athlete2.sets)
                      )
                    : Math.min(MatchCenterData.Athlete2.wonIn, MatchCenterData.Athlete1.wonIn)
                  : Math.min(
                      MatchCenterData.Athlete1.sets?.[0]?.Points,
                      MatchCenterData.Athlete2.sets?.[0]?.Points
                    )}
              </>
            )}
          </div>
          <h4 className={'font-roboto text-smd mt-4 font-bold text-black200'}>
            Match Overview{' '}
            <i
              onClick={refetch}
              className={`fa fa-refresh ${
                isFetching ? 'fa-spin' : ''
              } button-icon-right cursor-pointer`}
            ></i>
          </h4>
          <div className="md:hidden text-sm text-black200 font-bold mb-1 text-left">
            {' '}
            {MatchCenterData?.sport} {MatchCenterData?.sub_title} ({MatchCenterData?.match_id})
          </div>
          <div className={'flex flex-col flex-grow border-1 rounded-lg overflow-hidden'}>
            <div className={'border-b-1 bg-gray-125 px-2 flex justify-between text-xxs py-1'}>
              <div>Round 2</div>
              <div>
                <>
                  {realTimeStatus[matchStatus]
                    ? 'Current Score'
                    : !MatchCenterData?.is_draw
                    ? 'Won by'
                    : 'Draw by'}{' '}
                  {MatchCenterData.Athlete1.sets.length > 1 ||
                  MatchCenterData.Athlete2.sets.length > 1
                    ? MatchCenterData.scoringType === 'PointBased'
                      ? sumOfPoints(MatchCenterData.Athlete1.sets)
                      : MatchCenterData.Athlete1.wonIn
                    : MatchCenterData.Athlete1.sets?.[0]?.Points}
                  -
                  {MatchCenterData.Athlete1.sets.length > 1 ||
                  MatchCenterData.Athlete2.sets.length > 1
                    ? MatchCenterData.scoringType === 'PointBased'
                      ? sumOfPoints(MatchCenterData.Athlete2.sets)
                      : MatchCenterData.Athlete2.wonIn
                    : MatchCenterData.Athlete2.sets?.[0]?.Points}
                </>
              </div>
            </div>
            <div className={'flex-grow flex flex-col'}>
              <div className={'border-b-1 p-2'}>
                <AthleteInformationCard
                  data={MatchCenterData.Athlete1}
                  noColor
                  scoringType={MatchCenterData.scoringType}
                  isFontSmall
                  isDraw={MatchCenterData?.is_draw}
                  matchStatus={matchStatus}
                />
              </div>

              <div className={'p-2'}>
                <AthleteInformationCard
                  data={MatchCenterData.Athlete2}
                  noColor
                  scoringType={MatchCenterData.scoringType}
                  isFontSmall
                  isDraw={MatchCenterData?.is_draw}
                  matchStatus={matchStatus}
                />
              </div>
            </div>
            <div className={'border-t-1 bg-gray-125 px-2 flex justify-between py-1 text-xxs'}>
              <div>{MatchCenterData?.venue}</div>
              <div>
                {MatchCenterData?.match_datetime
                  ? 'at ' + getDateAndTime(MatchCenterData?.match_datetime)
                  : null}
              </div>
            </div>
          </div>
          <div className={'hidden'}>
            <h4 className={'font-roboto text-smd mt-4 font-bold text-black200'}>Video</h4>
            <Slider {...settings}>
              {videos.map((video, i) => (
                <div key={i} className={'w-32 h-full'}>
                  <div
                    onContextMenu={(e) => e.preventDefault()}
                    className="card-bg cursor-pointer w-full h-20 md:h-60 md:mx-4 carousel-item relative rounded-lg"
                    onClick={() => {
                      setModal(true);
                      setModalData(video);
                    }}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={
                        video.type === 'Image'
                          ? video.image
                          : video.type === 'Video(youtube)'
                          ? `https://ytimg.googleusercontent.com/vi/${video.video_id}/hqdefault.jpg`
                          : video.type === 'Video(vimeo)'
                          ? `https://vumbnail.com/${video.video_id}.jpg`
                          : null
                      }
                      alt=""
                      className="w-full h-full main_img block m-auto object-cover rounded-lg"
                    />
                    {video.type.toLowerCase().includes('video') && (
                      <div
                        className={
                          'absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-70 rounded-lg'
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlayCircle}
                          className=" text-gray-100 z-3 text-5xl"
                        />
                      </div>
                    )}
                    <div className="flex flex-col md:items-end md:flex-row  justify-between absolute bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                      <div>
                        <h4 className="text-white text-xs md:text-lg font-bold mt-1">
                          {video.media_name}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {(hideFinalFlag === undefined || hideFinalFlag.sets) &&
          (MatchCenterData.Athlete1.sets.length > 1 || MatchCenterData.Athlete2.sets.length > 1) ? (
            <>
              <div className={'flex items-center justify-between bg-gray-125 py-3 px-1'}>
                <AthleteInformationCard
                  data={MatchCenterData.Athlete1}
                  isCompact
                  isFontSmall
                  scoringType={MatchCenterData.scoringType}
                />

                <AthleteInformationCard
                  scoringType={MatchCenterData.scoringType}
                  data={MatchCenterData.Athlete2}
                  isReverse
                  isCompact
                  isFontSmall
                />
              </div>
              <h4 className={'font-roboto font-bold text-smd mt-4 text-black200'}>
                Final Score
                {MatchCenterData?.score_sheet && (
                  <span className="inline-block">
                    <LightGallery
                      enableDrag={false}
                      enableSwipe={false}
                      counter={false}
                      licenseKey={process.env.REACT_APP_LIGHT_GALLERY_LICENSE_KEY}
                      speed={500}
                      download={false}
                    >
                      <div
                        data-lg-size="1406-1390"
                        className="gallery-item text-sm"
                        data-src={MatchCenterData?.score_sheet}
                      >
                        <a> (View Scoresheet)</a>
                      </div>
                    </LightGallery>
                  </span>
                )}{' '}
              </h4>
              <div className={`flex overflow-x-scroll rounded-lg border-1 p-2 justify-start`}>
                <div className="flex mx-auto">
                  {MatchCenterData.Athlete1.sets.length > 1 ? (
                    MatchCenterData.Athlete1.sets.map((set, index) => (
                      <div className={'w-34 flex items-center flex-col gap-2 py-1'} key={index}>
                        <div
                          className={'text-sm text-center font-bold border-b-1 w-full py-1 px-2'}
                        >
                          {/* <FontAwesomeIcon icon={faCaretDown} className={'text-gray-250'} /> */}
                          {`${set.name.charAt(0).toUpperCase()}${set.name.substring(1)}`}
                        </div>
                        <div
                          style={{ background: MatchCenterData.Athlete1.color }}
                          className={
                            'p-1 text-sm rounded-md text-white font-bold font-roboto -ml-4 my-1'
                          }
                        >
                          {MatchCenterData.Athlete1?.sets[index]?.Points}
                        </div>
                        <div
                          style={{ background: MatchCenterData?.Athlete2?.color }}
                          className={
                            'p-1 text-sm rounded-md text-white font-bold font-roboto -ml-4 my-1'
                          }
                        >
                          {MatchCenterData?.Athlete2?.sets[index]?.Points}
                        </div>
                      </div>
                    ))
                  ) : MatchCenterData?.Athlete1?.sets?.length === 1 ? (
                    <>
                      <div
                        className={'font-bold font-sm font-roboto flex justify-center items-center'}
                      >
                        {MatchCenterData.Athlete1.sets?.[0]?.name}
                      </div>
                      <div className={'flex gap-4 justify-center items-center'}>
                        <div
                          className={'py-1 text-white rounded-lg font-bold font-roboto px-4'}
                          style={{ background: MatchCenterData.Athlete1.color }}
                        >
                          {MatchCenterData.Athlete1?.sets?.[0]?.Points}
                        </div>
                        <div
                          className={'text-white font-bold font-roboto rounded-lg px-4 py-1'}
                          style={{ background: MatchCenterData.Athlete2.color }}
                        >
                          {MatchCenterData.Athlete2.sets?.[0]?.Points}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* {MatchCenterData.Athlete1.sets
                .map((keys) =>
                  Object.keys(keys).some((el) => el !== 'Points' && el !== 'name' && el !== '')
                )
                .find((el) => el) ? (
                <>
                  <h1 className={'font-bold text-smd font-roboto mt-4 text-black200'}>Details</h1>
                  <div className={'flex flex-col'}>
                    {MatchCenterData.Athlete1.sets.length > 1 ? (
                      <Tabs
                        tabList={MatchCenterData.Athlete1.sets.map((item, index) => {
                          return {
                            name: `${item.name.charAt(0).toUpperCase()}${item.name.substring(1)}`,
                            component: <PointsGraph index={index} data={MatchCenterData} />,
                          };
                        })}
                        border="border-black200"
                        isDetailedScoring={true}
                        mobileDetailedScoring={true}
                      />
                    ) : MatchCenterData.Athlete1.sets.length === 1 ? (
                      <PointsGraph index={0} data={MatchCenterData} />
                    ) : null}
                  </div>
                </>
              ) : null} */}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MatchCenterMobile;
