const cardSizesConfig = {
  big: 'row-span-3',
  medium: 'row-span-2',
  small: 'row-span-1',
};

const VenueGalleryCard = ({ height, image, size }) => {
  return (
    <img src={image} className={` ${cardSizesConfig[size?.toLowerCase()]} h-full rounded-lg`} />
  );
};

export default VenueGalleryCard;
