import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import TestScript from './JotForm/testScript';
import { Helmet } from 'react-helmet';

const SignupJotForm = ({ customFormId = '' }) => {
  const params = useParams();
  const { location } = useHistory();
  useEffect(() => {
    document.body.classList.add('insideJotFormPage1234');
    return () => document.body.classList.remove('insideJotFormPage1234');
  }, []);
  useEffect(() => {
    if (window.ga) {
      window.ga('send', 'pageview');
    }
  }, [location?.pathname]);
  return (
    <>
      <Helmet>
        <title>{customFormId !== 222423730792454 ? 'Registration' : 'Sports Day'}</title>
      </Helmet>
      <div className="flex flex-col justify-between min-h-screen">
        <div className="py-4 md:pb-8 h-full flex" style={{ flex: 1 }}>
          <iframe
            src={`https://form.jotform.com/${params.formId || customFormId}`}
            scrolling="yes"
            className=" w-full"
            style={{ flex: 1 }}
          />
        </div>
      </div>
    </>
  );
};

export default SignupJotForm;
