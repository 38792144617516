const MODULE_NAME = 'profile';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  LOGGED_IN_USER_PROFILE: generateKeyName('logged-in-user-profile'),
  GET_TOURNAMENTS_DETAILS: generateKeyName('tournament-details'),
  GET_TOURNAMENTS_LIST: generateKeyName('tournament-list'),
  GET_NOTIFICATIONS_LIST: generateKeyName('notifications'),
  GET_TOURNAMENT_EVENTS_LIST: generateKeyName('tournament-events-list'),
  GET_MATCH_DETAILS_BY_SPORT_EVENT: generateKeyName('match-details-by-sport-event'),
  GET_MEDAL_WINNER_BY_SPORT_EVENT: generateKeyName('medal-winner-by-sport-event'),
  GET_SPORT_LIST_BY_TOURNAMENT_ID: generateKeyName('sport-list-by-tournament-id'),
  GET_SPORT_EVENT_LIST: generateKeyName('sport-event-list'),
  GET_MATCH_FIXTURE_DATES: generateKeyName('match-fixture-dates'),
  GET_MATCH_FIXTURE_LIST: generateKeyName('match-fixture-list'),
  GET_CONTINGENT_LIST: generateKeyName('contingent-list'),
  GET_CONTINGENT_DETAILS: generateKeyName('contingent-details'),
  GET_ALL_SPORT_EVENTS_UNDER_TOURNAMENT_LIST: generateKeyName(
    'all-sport-events-under-tournament-list'
  ),
  GET_TEAM_UNDER_SPORT_EVENT_LIST: generateKeyName('team-under-sport-event-list'),
  GET_PROFILE_OVERVIEW: generateKeyName('profile-overview'),
  GET_PROFILE_RANK: generateKeyName('profile-rank'),
  GET_TEAM_DETAILS: generateKeyName('team-details'),
  GET_TEAM_PLAYERS_LIST: generateKeyName('team-players-list'),
  GET_TEAM_MEDAL_WINNERS: generateKeyName('team-medal-winners'),
  GET_TEAM_MATCH_FIXTURES: generateKeyName('team-match-fixtures'),
  GET_TEAM_CONFIRMATION_FORM: generateKeyName('team-confirmation-form'),
  GET_COACH_VERIFICATION_STATUS: generateKeyName('coach-verification-status'),
  GET_PROFILE_BILLING_DETAILS: generateKeyName('profile-billing-information'),
  GET_FORM_DATA: generateKeyName('get-submitted-data'),
  GET_INSTITUTION_VERIFICATION_STATUS: generateKeyName('institute-verification-status'),
  GET_SPORT_VIDEO_ID: generateKeyName('sport-video-id'),
  GET_DIGITAL_SCORING_SHOW: generateKeyName('show-digital-scoring'),
  GET_MATCH_ANALYTICS: generateKeyName('match-analytics'),
  GET_ACHIEVEMENTS: generateKeyName('get-achievements'),
  GET_ACHIEVEMENT_FILTERS: generateKeyName('get-achievement-filters'),
  GET_PROFILE_VIDEOS: generateKeyName('get-profile-videos'),
  GET_DASHBOARD_LIST: generateKeyName('get-dashboard-list'),
  GET_ADVANCE_DS: generateKeyName('get-advance-ds'),
  GET_CONTINGENT_FILTERS: generateKeyName('get-contingent-filters'),
};

export default QUERY_KEYS;
